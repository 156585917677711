import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { tokenInterceptor } from "../../utilities/authMidleware";
import { backendURL, config } from "../../utilities/utils";
import { displayConsoleError } from "../../utilities/helperFunctions";

const propertyRoute = "/api/v1/property";

const getAllPropertyTypes = createAsyncThunk(
  "property/getAllPropertyTypes",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}${propertyRoute}/getAllPropertyTypes`,
        config
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getAllPropertyTypes", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getAllCountries = createAsyncThunk(
  "property/getAllCountries",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}${propertyRoute}/getAllCountries`,
        config
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getAllCountries", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getAllStates = createAsyncThunk(
  "property/getAllStates",
  async ({ country_id }: { country_id: any }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/getAllStates`,
        { country_id },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getAllStates", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getStateAllCities = createAsyncThunk(
  "property/getStateAllCities",
  async ({ state_id }: { state_id: any }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/getStateAllCities`,
        { state_id },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getStateAllCities", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getCountryAllCities = createAsyncThunk(
  "property/getCountryAllCities",
  async ({ country_id }: { country_id: any }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/getCountryAllCities`,
        { country_id },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getCountryAllCities", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getPropertyType = createAsyncThunk(
  "property/propertyType",
  async (
    { property_type_id }: { property_type_id: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}/api/v1/property/getPropertyType`,
        {
          property_type_id,
        },
        config
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getPropertyType", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getAllPropertyRoomType = createAsyncThunk(
  "property/getAllPropertyRoomType",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}${propertyRoute}/getAllPropertyRoomType`,
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getAllPropertyRoomType", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getAllPropertyListingType = createAsyncThunk(
  "property/getAllPropertyListingType",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}${propertyRoute}/getAllPropertyListingType`,
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getAllPropertyListingType", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getOwnerProperties = createAsyncThunk(
  "property/getOwnerProperties",
  async (
    { user_id, account_id }: { user_id: string; account_id: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/getOwnerProperties`,
        { user_id, account_id },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getOwnerProperties", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const addOwnerProperty = createAsyncThunk(
  "property/addOwnerProperty",
  async ({ values }: { values: any }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/addOwnerProperty`,
        values,
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("addOwnerProperty", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const uploadPropertyImages = createAsyncThunk(
  "property/uploadPropertyImages",
  async (
    { images, property_id }: { images: any; property_id: string },
    { rejectWithValue }
  ) => {
    const tempHeaders = tokenInterceptor();
    const filelist = Array.from(images);
    const formData = new FormData();
    formData.append("property_id", property_id);
    filelist.forEach((element: any, index: any) => {
      formData.append(`image_${index}`, element);
    });

    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/uploadPropertyImages`,
        formData,
        {
          ...tempHeaders,
          headers: {
            ...tempHeaders.headers,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("uploadPropertyImages", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getPropertySpecification = createAsyncThunk(
  "property/propertySpecification",
  async ({ property_id }: { property_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/getPropertySpecifications`,
        {
          property_id,
        },
        config
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getPropertySpecification", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getPropertyImages = createAsyncThunk(
  "property/getPropertyImages",
  async (
    { property_id, count }: { property_id: string; count?: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/getPropertyImages`,
        {
          property_id,
          count,
        },
        config
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getPropertyImages", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getSpecificProperty = createAsyncThunk(
  "property/getSpecificProperty",
  async ({ property_id }: { property_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/getSpecificProperty`,
        {
          property_id,
        },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getSpecificProperty", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const updatePropertyStatus = createAsyncThunk(
  "property/updatePropertyStatus",
  async (
    { property_id, status_id }: { property_id: string; status_id: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/updatePropertyStatus`,
        {
          property_id,
          status_id,
        },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("updatePropertyStatus", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const updatePropertyOwner = createAsyncThunk(
  "property/updatePropertyOwner",
  async (
    { property_id, user_id }: { property_id: string; user_id: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/updatePropertyOwner`,
        {
          property_id,
          user_id,
        },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("updatePropertyOwner", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const updatePropertyDetails = createAsyncThunk(
  "property/updatePropertyDetails",
  async (
    { property_detail_payload }: { property_detail_payload: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/updatePropertyDetails`,
        {
          property_detail_payload,
        },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("updatePropertyDetails", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const updatePropertyPrices = createAsyncThunk(
  "property/updatePropertyPrices",
  async (
    { property_price_payload }: { property_price_payload: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/updatePropertyPrices`,
        {
          property_price_payload,
        },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("updatePropertyPrices", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const updatePropertySpecifications = createAsyncThunk(
  "property/updatePropertySpecifications",
  async (
    {
      property_specifications_payload,
      all_property_specification,
      property_id,
    }: {
      property_specifications_payload: any;
      all_property_specification: any;
      property_id: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/updatePropertySpecifications`,
        {
          property_specifications_payload,
          all_property_specification,
          property_id,
        },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("updatePropertySpecifications", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getPropertySpecificationsColumns = createAsyncThunk(
  "property/getPropertySpecificationsColumns",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}${propertyRoute}/getPropertySpecificationsColumns`,
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getPropertySpecificationsColumns", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const updatePropertyImages = createAsyncThunk(
  "property/updatePropertyImages",
  async (
    {
      images,
      property_id,
      removed_images,
    }: { images: any; property_id: string; removed_images: any },
    { rejectWithValue }
  ) => {
    const tempHeaders = tokenInterceptor();

    const formData = new FormData();

    formData.append("property_id", property_id);
    formData.append("remove_images_payload", JSON.stringify(removed_images));
    if (images) {
      const filelist = Array.from(images);
      filelist.forEach((element: any, index: any) => {
        formData.append(`image_${index}`, element);
      });
    }

    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/updatePropertyImages`,
        formData,
        {
          ...tempHeaders,
          headers: {
            ...tempHeaders.headers,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("updatePropertyImages", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const blockPropertyReservation = createAsyncThunk(
  "property/blockPropertyReservation",
  async (
    { blockReservationInfo }: { blockReservationInfo: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/blockPropertyReservation`,
        {
          blockReservationInfo,
        },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("blockPropertyReservation", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const addPropertyExpense = createAsyncThunk(
  "property/addPropertyExpense",
  async (
    { property_expense_payload }: { property_expense_payload: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}${propertyRoute}/addPropertyExpense`,
        { property_expense_payload },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("addPropertyExpense", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export {
  getOwnerProperties,
  addOwnerProperty,
  getAllPropertyTypes,
  getAllCountries,
  getAllStates,
  getStateAllCities,
  uploadPropertyImages,
  getAllPropertyRoomType,
  getAllPropertyListingType,
  getPropertyType,
  getPropertySpecification,
  getPropertyImages,
  getSpecificProperty,
  updatePropertyStatus,
  getCountryAllCities,
  updatePropertyOwner,
  updatePropertyDetails,
  updatePropertyPrices,
  updatePropertySpecifications,
  getPropertySpecificationsColumns,
  updatePropertyImages,
  blockPropertyReservation,
  addPropertyExpense,
};
