import { createSlice } from "@reduxjs/toolkit";
import {
  getAllUpsellProducts,
  addUpsellProduct,
  enableUpsellProduct,
  disableUpsellProduct,
  updateUpsellProductDetails,
  getUpsellProductTypes,
  getUpsellProductIntervals,
  updateUpsellProductImage,
} from "../../thunks/upsellThunk";

interface UpsellSlice {
  getAllUpsellProductsLoading: Boolean;
  addUpsellProductLoading: Boolean;
  enableUpsellProductLoading: Boolean;
  disableUpsellProductLoading: Boolean;
  updateUpsellProductDetailsLoading: Boolean;
  getUpsellProductTypesLoading: Boolean;
  getUpsellProductIntervalsLoading: Boolean;
  updateUpsellProductImageLoading: Boolean;
  error: any;
  response: any;
  allUpsellProducts: any;
  allUpsellProductTypes: any;
  allUpsellProductIntervals: any;
}

const initialState: UpsellSlice = {
  getAllUpsellProductsLoading: false,
  addUpsellProductLoading: false,
  enableUpsellProductLoading: false,
  disableUpsellProductLoading: false,
  updateUpsellProductDetailsLoading: false,
  getUpsellProductTypesLoading: false,
  getUpsellProductIntervalsLoading: false,
  updateUpsellProductImageLoading: false,
  error: null,
  response: null,
  allUpsellProducts: null,
  allUpsellProductTypes: null,
  allUpsellProductIntervals: null,
};

const upsellSlice = createSlice({
  name: "upsell",
  initialState,
  reducers: {
    resetUpsell: (state) => {
      state.getAllUpsellProductsLoading = false;
      state.addUpsellProductLoading = false;
      state.enableUpsellProductLoading = false;
      state.disableUpsellProductLoading = false;
      state.updateUpsellProductDetailsLoading = false;
      state.getUpsellProductTypesLoading = false;
      state.getUpsellProductIntervalsLoading = false;
      state.updateUpsellProductImageLoading = false;
      state.allUpsellProducts = null;
      state.allUpsellProductTypes = null;
      state.allUpsellProductIntervals = null;
      state.error = null;
      state.response = null;
    },
  },
  extraReducers: (builder) => {
    // Get All Upsell Products
    builder.addCase(getAllUpsellProducts.pending, (state) => {
      state.getAllUpsellProductsLoading = true;
    });
    builder.addCase(getAllUpsellProducts.fulfilled, (state, action) => {
      state.getAllUpsellProductsLoading = false;
      state.allUpsellProducts = action.payload.all_upsell_products;
    });
    builder.addCase(getAllUpsellProducts.rejected, (state, action) => {
      state.getAllUpsellProductsLoading = false;
      state.error = action.payload;
    });
    // Add Upsell Product
    builder.addCase(addUpsellProduct.pending, (state) => {
      state.addUpsellProductLoading = true;
    });
    builder.addCase(addUpsellProduct.fulfilled, (state, action) => {
      state.addUpsellProductLoading = false;
      state.response = action.payload;
    });
    builder.addCase(addUpsellProduct.rejected, (state, action) => {
      state.addUpsellProductLoading = false;
      state.error = action.payload;
    });
    // Enable Upsell Product
    builder.addCase(enableUpsellProduct.pending, (state) => {
      state.enableUpsellProductLoading = true;
    });
    builder.addCase(enableUpsellProduct.fulfilled, (state, action) => {
      state.enableUpsellProductLoading = false;
      state.response = action.payload;
    });
    builder.addCase(enableUpsellProduct.rejected, (state, action) => {
      state.enableUpsellProductLoading = false;
      state.error = action.payload;
    });
    // Disable Upsell Product
    builder.addCase(disableUpsellProduct.pending, (state) => {
      state.disableUpsellProductLoading = true;
    });
    builder.addCase(disableUpsellProduct.fulfilled, (state, action) => {
      state.disableUpsellProductLoading = false;
      state.response = action.payload;
    });
    builder.addCase(disableUpsellProduct.rejected, (state, action) => {
      state.disableUpsellProductLoading = false;
      state.error = action.payload;
    });
    // Update Upsell Product Details
    builder.addCase(updateUpsellProductDetails.pending, (state) => {
      state.updateUpsellProductDetailsLoading = true;
    });
    builder.addCase(updateUpsellProductDetails.fulfilled, (state, action) => {
      state.updateUpsellProductDetailsLoading = false;
      state.response = action.payload;
    });
    builder.addCase(updateUpsellProductDetails.rejected, (state, action) => {
      state.updateUpsellProductDetailsLoading = false;
      state.error = action.payload;
    });
    // Get Upsell Product Types
    builder.addCase(getUpsellProductTypes.pending, (state) => {
      state.getUpsellProductTypesLoading = true;
    });
    builder.addCase(getUpsellProductTypes.fulfilled, (state, action) => {
      state.getUpsellProductTypesLoading = false;
      state.allUpsellProductTypes = action.payload.upsell_product_types;
    });
    builder.addCase(getUpsellProductTypes.rejected, (state, action) => {
      state.getUpsellProductTypesLoading = false;
      state.error = action.payload;
    });
    // Get Upsell Product Intervals
    builder.addCase(getUpsellProductIntervals.pending, (state) => {
      state.getUpsellProductIntervalsLoading = true;
    });
    builder.addCase(getUpsellProductIntervals.fulfilled, (state, action) => {
      state.getUpsellProductIntervalsLoading = false;
      state.allUpsellProductIntervals = action.payload.upsell_product_intervals;
    });
    builder.addCase(getUpsellProductIntervals.rejected, (state, action) => {
      state.getUpsellProductIntervalsLoading = false;
      state.error = action.payload;
    });
    // Update Upsell Product Image
    builder.addCase(updateUpsellProductImage.pending, (state) => {
      state.updateUpsellProductImageLoading = true;
    });
    builder.addCase(updateUpsellProductImage.fulfilled, (state, action) => {
      state.updateUpsellProductImageLoading = false;
      state.response = action.payload;
    });
    builder.addCase(updateUpsellProductImage.rejected, (state, action) => {
      state.updateUpsellProductImageLoading = false;
      state.error = action.payload;
    });
  },
});

export const { resetUpsell } = upsellSlice.actions;

export const upsellReducer = upsellSlice.reducer;
