import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Typography } from "@mui/material";

export default function AlternateTimeline() {
  return (
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            sx={{
              position: "relative",
              width: "32px",
              height: "33px",
              background: "#A6F5FFFF",
              borderRadius: "50%",
            }}
          >
            <TimelineDot
              sx={{
                position: "absolute",
                top: "-5px",
                left: "7px",
                width: "15px",
                height: "15px",
                background: "#1CE5FFFF",
                borderRadius: "50%",
              }}
            />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography
            variant="body1"
            sx={{ fontSize: "1.2rem", padding: "10px 0 0 0", maxWidth: "70%" }}
          >
            At LVLpm, we specialize in providing hotel-quality experiences
            through curated, well-designed properties. Every stay is crafted to
            offer the perfect blend of comfort, luxury, and local flavor,
            ensuring guests feel at home while experiencing something
            extraordinary.
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            sx={{
              position: "relative",
              width: "32px",
              height: "33px",
              background: "#A6F5FFFF",
              borderRadius: "50%",
            }}
          >
            <TimelineDot
              sx={{
                position: "absolute",
                top: "-5px",
                left: "7px",
                width: "15px",
                height: "15px",
                background: "#1CE5FFFF",
                borderRadius: "50%",
              }}
            />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1.2rem",
              padding: "10px 0 0 0",
              textAlign: "left",
              maxWidth: "70%",
              marginLeft: "auto",
            }}
          >
            Our platform empowers property owners with automated, stress-free
            management solutions. With a focus on transparency, automation, and
            smart integrations, owners unlock sustainable income with minimal
            effort while maintaining complete visibility over their assets.
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            sx={{
              position: "relative",
              width: "32px",
              height: "33px",
              background: "#A6F5FFFF",
              borderRadius: "50%",
            }}
          >
            <TimelineDot
              sx={{
                position: "absolute",
                top: "-5px",
                left: "7px",
                width: "15px",
                height: "15px",
                background: "#1CE5FFFF",
                borderRadius: "50%",
              }}
            />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography
            variant="body1"
            sx={{ fontSize: "1.2rem", padding: "10px 0 0 0", maxWidth: "70%" }}
          >
            Our partnerships with local vendors allow us to offer personalized
            concierge services, including in-app guidebooks, exclusive local
            recommendations, and add-on services such as private chefs,
            excursions, and wellness experiences.
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            sx={{
              position: "relative",
              width: "32px",
              height: "33px",
              background: "#A6F5FFFF",
              borderRadius: "50%",
            }}
          >
            <TimelineDot
              sx={{
                position: "absolute",
                top: "-5px",
                left: "7px",
                width: "15px",
                height: "15px",
                background: "#1CE5FFFF",
                borderRadius: "50%",
              }}
            />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              textAlign: "left",
              padding: "10px 0 0 0",
              maxWidth: "70%",
              marginLeft: "auto",
            }}
          >
            2024
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1.2rem",
              padding: "10px 0 0 0",
              textAlign: "left",
              maxWidth: "70%",
              marginLeft: "auto",
            }}
          >
            With a vision to become the global leader in experiential
            hospitality, LVLpm continually innovates to offer more ways to earn
            and enjoy. Whether through unique in-home amenities, immersive
            travel experiences, or next-generation concierge services, we’re
            redefining what it means to feel at home—anywhere.
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
