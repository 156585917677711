import { SyntheticEvent, useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { LVLpmThemeButton } from "../../../../utilities/constants";
import { updateUserAccount } from "../../../../store/thunks/userThunk";
import { displayConsoleError } from "../../../../utilities/helperFunctions";
import Spinner from "../../../../components/reusableComponents/spinner/spinner";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { addUpsellProduct } from "../../../../store/thunks/upsellThunk";

interface AddUpsellProductDialogProps {
  open: boolean;
  handleCloseAddUpsellProduct: (message: string) => void;
}

interface UpsellProductFormValues {
  upsell_product_name: string;
  upsell_product_description: string;
  upsell_product_price: number;
  upsell_product_image: File | null;
  upsell_product_type: any;
  upsell_account: any;
  upsell_product_interval: any;
  upsell_product_interval_frequency: number;
}

const AddUpsellProductDialog = (props: AddUpsellProductDialogProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { open, handleCloseAddUpsellProduct } = props;
  const { userInfo } = useAppSelector((state: any) => state.authentication);
  const {
    allUpsellProductTypes,
    allUpsellProductIntervals,
    addUpsellProductLoading,
  } = useAppSelector((state: any) => state.upsell);

  const { allAccounts } = useAppSelector((state: any) => state.system);
  const [upsellAccountOptions, setUpsellAccountOptions] = useState<any[]>([]);

  const [addUpsellProductError, setAddUpsellProductError] = useState(false);
  const [addUpsellProductErrorMessage, setAddUpsellProductErrorMessage] =
    useState("");

  const upsellInitialValues: UpsellProductFormValues = {
    upsell_product_name: "",
    upsell_product_description: "",
    upsell_product_price: 0,
    upsell_product_image: null,
    upsell_product_type: null,
    upsell_account: null,
    upsell_product_interval: null,
    upsell_product_interval_frequency: 0,
  };

  const validateUpsellProduct = Yup.object({
    upsell_product_name: Yup.string().required(
      "Upsell product name is required"
    ),
    upsell_product_description: Yup.string().required(
      "Upsell product description is required"
    ),
    upsell_product_price: Yup.number().when(
      "upsell_product_type",
      (upsell_product_type: any, schema: any) => {
        return upsell_product_type?.length > 0 &&
          (upsell_product_type[0]?.upsell_product_type_id === "2" ||
            upsell_product_type[0]?.upsell_product_type_id === "1")
          ? schema
              .min(1, "Upsell product price must be greater than 0")
              .required("Upsell product price is required")
          : schema.default(null).nullable();
      }
    ),
    upsell_product_image: Yup.mixed().required(
      "Upsell product image is required"
    ),
    upsell_product_type: Yup.mixed().required(
      "Upsell product type is required"
    ),
    upsell_account: Yup.mixed().required("Upsell account is required"),
    upsell_product_interval: Yup.object().when(
      "upsell_product_type",
      (upsell_product_type: any, schema: any) => {
        return upsell_product_type?.length > 0 &&
          upsell_product_type[0]?.upsell_product_type_id === "2"
          ? schema.required("Upsell product interval is required")
          : schema.default(null).nullable();
      }
    ),
    upsell_product_interval_frequency: Yup.number().when(
      "upsell_product_type",
      (upsell_product_type: any, schema: any) => {
        return upsell_product_type?.length > 0 &&
          upsell_product_type[0]?.upsell_product_type_id === "2"
          ? schema
              .min(
                1,
                "Upsell product interval frequency must be greater than 0"
              )
              .required("Upsell product interval frequency is required")
          : schema.default(null).nullable();
      }
    ),
  });

  const UpsellProductForm = useFormik({
    initialValues: upsellInitialValues,
    validationSchema: validateUpsellProduct,
    onSubmit: (values: any, { setErrors, resetForm }) => {
      setAddUpsellProductError(false);
      setAddUpsellProductErrorMessage("");
      if (values.upsell_product_image.length === 0) {
        setErrors({
          upsell_product_image: "Upsell product image is required",
        });
        return;
      }
      const upsellProductPayload = {
        upsell_product_name: values.upsell_product_name,
        upsell_product_description: values.upsell_product_description,
        upsell_product_price: values.upsell_product_price,
        upsell_product_image: values.upsell_product_image,
        account_id: values.upsell_account?.account_id,
        account_name: values.upsell_account?.account_name,
        account_label: values.upsell_account?.label,
        upsell_product_type_id:
          values.upsell_product_type?.upsell_product_type_id,
        upsell_product_type_name:
          values.upsell_product_type?.upsell_product_type_name,
        upsell_product_type_label: values.upsell_product_type?.label,
        upsell_product_interval_id:
          values.upsell_product_interval?.upsell_product_interval_id,
        upsell_product_interval_name:
          values.upsell_product_interval?.upsell_product_interval_name,
        upsell_product_interval_label:
          values.upsell_product_interval?.upsell_product_interval_label,
        upsell_product_interval_frequency:
          values.upsell_product_interval_frequency,
      };

      dispatch(addUpsellProduct({ upsellProductPayload }))
        .then((addUpsellProductResult: any) => {
          if (addUpsellProductResult.payload?.result) {
            handleCloseAddUpsellProductDialog(
              addUpsellProductResult.payload?.message
            );
          } else {
            setAddUpsellProductError(true);
            setAddUpsellProductErrorMessage(
              addUpsellProductResult.payload?.message
            );
          }
        })
        .catch((addUpsellProductError: any) => {
          setAddUpsellProductError(true);
          setAddUpsellProductErrorMessage(
            addUpsellProductError?.response?.data?.error?.message
          );
        });
    },
  });

  useEffect(() => {
    if (allAccounts) {
      const accountOptions = allAccounts.filter((account: any) => {
        if (account.upsell) {
          return account;
        }
      });
      setUpsellAccountOptions(accountOptions);
    }
  }, [allAccounts]);

  const handleCloseAddUpsellProductDialog = (message: any) => {
    UpsellProductForm.resetForm();
    handleCloseAddUpsellProduct(message);
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth={true} scroll="paper">
      <Spinner loading={addUpsellProductLoading} />
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Add Upsell Product
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={(event: any) => handleCloseAddUpsellProductDialog("")}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <form onSubmit={UpsellProductForm.handleSubmit}>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4} md={4} lg={4} sx={{ padding: "1%" }}>
                  <TextField
                    fullWidth
                    id="upsell_product_name"
                    label="Product Name"
                    name="upsell_product_name"
                    value={UpsellProductForm.values.upsell_product_name}
                    onChange={UpsellProductForm.handleChange}
                    error={
                      UpsellProductForm.touched.upsell_product_name &&
                      Boolean(UpsellProductForm.errors.upsell_product_name)
                    }
                    helperText={
                      UpsellProductForm.touched.upsell_product_name &&
                      Boolean(UpsellProductForm.errors.upsell_product_name)
                        ? "Product name is required"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={4} md={4} lg={4} sx={{ padding: "1%" }}>
                  <Textarea
                    minRows={3}
                    id="upsell_product_description"
                    name="upsell_product_description"
                    placeholder="Product Description"
                    value={UpsellProductForm.values.upsell_product_description}
                    onChange={UpsellProductForm.handleChange}
                    error={
                      UpsellProductForm.touched.upsell_product_description &&
                      Boolean(
                        UpsellProductForm.errors.upsell_product_description
                      )
                    }
                  />
                </Grid>
                <Grid item xs={4} md={4} lg={4} sx={{ padding: "1%" }}>
                  <Autocomplete
                    options={upsellAccountOptions}
                    value={UpsellProductForm.values.upsell_account}
                    sx={{ width: "100%" }}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.account_id === value?.account_id
                    }
                    onChange={(event: any, value: any) => {
                      UpsellProductForm.setFieldValue("upsell_account", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ textTransform: "capitalize" }}
                        label={"Select Account Type"}
                        id="upsell_account"
                        name="upsell_account"
                        error={
                          UpsellProductForm.touched.upsell_account &&
                          Boolean(UpsellProductForm.errors.upsell_account)
                        }
                        helperText={
                          UpsellProductForm.touched.upsell_account &&
                          Boolean(UpsellProductForm.errors.upsell_account)
                            ? "Upsell account is required"
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} sx={{ padding: "1%" }}>
                  <Autocomplete
                    options={allUpsellProductTypes ? allUpsellProductTypes : []}
                    value={UpsellProductForm.values.upsell_product_type}
                    sx={{ width: "100%" }}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.upsell_product_type_id ===
                      value?.upsell_product_type_id
                    }
                    onChange={(event: any, value: any) => {
                      UpsellProductForm.setFieldValue(
                        "upsell_product_type",
                        value
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ textTransform: "capitalize" }}
                        label={"Select Product Type"}
                        id="upsell_product_type"
                        name="upsell_product_type"
                        error={
                          UpsellProductForm.touched.upsell_product_type &&
                          Boolean(UpsellProductForm.errors.upsell_product_type)
                        }
                        helperText={
                          UpsellProductForm.touched.upsell_product_type &&
                          Boolean(UpsellProductForm.errors.upsell_product_type)
                            ? "Product type is required"
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
                {(UpsellProductForm.values.upsell_product_type
                  ?.upsell_product_type_id === "2" ||
                  UpsellProductForm.values.upsell_product_type
                    ?.upsell_product_type_id === "1") && (
                  <Grid item xs={6} sx={{ padding: "1%" }}>
                    <TextField
                      fullWidth
                      id="upsell_product_price"
                      label="Product Price"
                      name="upsell_product_price"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      value={UpsellProductForm.values.upsell_product_price}
                      onChange={UpsellProductForm.handleChange}
                      error={
                        UpsellProductForm.touched.upsell_product_price &&
                        Boolean(UpsellProductForm.errors.upsell_product_price)
                      }
                      helperText={
                        UpsellProductForm.touched.upsell_product_price &&
                        Boolean(UpsellProductForm.errors.upsell_product_price)
                          ? "Upsell product price is required"
                          : ""
                      }
                    />
                  </Grid>
                )}
              </Grid>
              {UpsellProductForm.values.upsell_product_type
                ?.upsell_product_type_id === "2" && (
                <Grid container>
                  <Grid item xs={6} sx={{ padding: "1%" }}>
                    <Autocomplete
                      options={
                        allUpsellProductIntervals
                          ? allUpsellProductIntervals
                          : []
                      }
                      value={UpsellProductForm.values.upsell_product_interval}
                      sx={{ width: "100%" }}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option?.upsell_product_interval_id ===
                        value?.upsell_product_interval_id
                      }
                      getOptionLabel={(option: any) =>
                        option?.upsell_product_interval_label
                      }
                      onChange={(event: any, value: any) => {
                        UpsellProductForm.setFieldValue(
                          "upsell_product_interval",
                          value
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ textTransform: "capitalize" }}
                          label={"Recurrence Type"}
                          id="upsell_product_interval"
                          name="upsell_product_interval"
                          error={
                            UpsellProductForm.touched.upsell_product_interval &&
                            Boolean(
                              UpsellProductForm.errors.upsell_product_interval
                            )
                          }
                          helperText={
                            UpsellProductForm.touched.upsell_product_interval &&
                            Boolean(
                              UpsellProductForm.errors.upsell_product_interval
                            )
                              ? "Upsell product interval is required"
                              : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ padding: "1%" }}>
                    <TextField
                      fullWidth
                      id="upsell_product_interval_frequency"
                      label="Recurrence Frequency"
                      name="upsell_product_interval_frequency"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      value={
                        UpsellProductForm.values
                          .upsell_product_interval_frequency
                      }
                      onChange={UpsellProductForm.handleChange}
                      error={
                        UpsellProductForm.touched
                          .upsell_product_interval_frequency &&
                        Boolean(
                          UpsellProductForm.errors
                            .upsell_product_interval_frequency
                        )
                      }
                      helperText={
                        UpsellProductForm.touched
                          .upsell_product_interval_frequency &&
                        Boolean(
                          UpsellProductForm.errors
                            .upsell_product_interval_frequency
                        )
                          ? "Upsell product interval frequency is required"
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} sx={{ padding: "1%" }}>
                <TextField
                  type="file"
                  id="upsell_product_image"
                  name="upsell_product_image"
                  inputProps={{
                    accept: "image/*",
                  }}
                  onChange={(event: any) => {
                    UpsellProductForm.setFieldValue(
                      "upsell_product_image",
                      event.target.files
                    );
                  }}
                  error={
                    UpsellProductForm.touched.upsell_product_image &&
                    Boolean(UpsellProductForm.errors.upsell_product_image)
                  }
                  helperText={
                    UpsellProductForm.touched.upsell_product_image &&
                    Boolean(UpsellProductForm.errors.upsell_product_image)
                      ? "Upsell product image is required"
                      : ""
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          {addUpsellProductError && (
            <Grid item xs={12} sx={{ padding: "1%", justifyContent: "center" }}>
              <Typography>{addUpsellProductErrorMessage}</Typography>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <LVLpmThemeButton
            variant="contained"
            onClick={(event: any) => handleCloseAddUpsellProductDialog("")}
          >
            Cancel
          </LVLpmThemeButton>
          <LVLpmThemeButton
            variant="contained"
            onClick={() => UpsellProductForm.handleSubmit()}
          >
            Submit
          </LVLpmThemeButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddUpsellProductDialog;
