import {
  Box,
  Button,
  Checkbox,
  Grid,
  ListItemIcon,
  MenuItem,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import TableTemplate from "../../../components/reusableComponents/tableTemplate/tableTemplate";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Update";
import Spinner from "../../../components/reusableComponents/spinner/spinner";
import { displayConsoleError } from "../../../utilities/helperFunctions";
import { logoutUser } from "../../../store/thunks/authenticationThunk";
import { logout, resetMessage, setPreviousLocation } from "../../../store";
import { getAllUsers } from "../../../store/thunks/userThunk";
import {
  disableUpsellProduct,
  enableUpsellProduct,
  getAllUpsellProducts,
  getUpsellProductIntervals,
  getUpsellProductTypes,
} from "../../../store/thunks/upsellThunk";
import { LVLpmThemeButton } from "../../../utilities/constants";
import AddUpsellProductDialog from "./addUpsellProductDialog/addUpsellProductDialog";
import UpdateUpsellProductDetailsDialog from "./updateUpsellProductDetailsDialog/updateUpsellProductDetailsDialog";
import UpdateUpsellProductImageDialog from "./updateUpsellProductImageDialog/updateUpsellProductImageDialog";
import { getAllAccounts } from "../../../store/thunks/systemThunk";

const UpsellManagement = () => {
  const { userInfo } = useAppSelector((state: any) => state.authentication);

  const {
    getAllUpsellProductsLoading,
    enableUpsellProductLoading,
    disableUpsellProductLoading,
  } = useAppSelector((state: any) => state.upsell);

  const dispatch = useAppDispatch();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [upsellTableData, setUpsellTableData] = useState<any[]>([]);

  const [openAddUpsellProductDialog, setOpenAddUpsellProductDialog] =
    useState(false);

  const [
    openUpdateUpsellProductDetailsDialog,
    setOpenUpdateUpsellProductDetailsDialog,
  ] = useState(false);
  const [
    openUpdateUpsellProductImageDialog,
    setOpenUpdateUpsellProductImageDialog,
  ] = useState(false);

  const [selectedUpsellProductData, setSelectedUpsellProductData] =
    useState<any>();

  const {
    data: { data = [] } = {}, //your data and api response will probably be different
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<any>({
    queryKey: ["table-data"],
    queryFn: async () => {
      const response: any = await dispatch(
        getAllUpsellProducts({ account_id: userInfo.account_id })
      )
        .then((getAllUpsellProductsResult: any) => {
          if (getAllUpsellProductsResult?.error) {
            if (
              getAllUpsellProductsResult?.error?.message === "Rejected" ||
              getAllUpsellProductsResult?.error?.response?.status === 401
            ) {
              // dispatch(logoutUser({ session_id: userInfo?.session_id }));
              // dispatch(logout());
              // dispatch(resetMessage());
              // dispatch(setPreviousLocation(location.pathname));
              // navigate("/login");
            }
          }
          if (getAllUpsellProductsResult?.payload) {
            setUpsellTableData(
              getAllUpsellProductsResult?.payload.all_upsell_products
            );
          }
        })
        .catch((getAllUpsellProductsError) => {
          displayConsoleError(
            "getAllUpsellProducts",
            getAllUpsellProductsError
          );
        });
      return response ? response : {};
    },
    placeholderData: keepPreviousData,
  });

  const upsellColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "upsell_product_name",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Product Name",
      },
      {
        accessorKey: "upsell_product_type_label",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Product Type",
      },
      {
        accessorKey: "upsell_product_description",
        filterVariant: "autocomplete",
        header: "Product Description",
      },
      {
        accessorKey: "upsell_price",
        filterVariant: "autocomplete",
        header: "Price",
      },
      {
        accessorKey: "account_label",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Account Type",
      },
      {
        accessorKey: "upsell_product_interval_label",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Recurrence",
      },
      {
        accessorKey: "upsell_product_interval_frequency",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Frequency",
      },
      {
        accessorFn: (row: any) =>
          dayjs(row.upsell_product_created_date).format("MMM DD, YYYY"),
        id: "upsell_product_create_date",
        header: "Created Date",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorKey: "status_label",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Status",
      },
    ],
    []
  );

  const upsellTable = useMaterialReactTable({
    columns: upsellColumns,
    data: upsellTableData ? upsellTableData : [], //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    state: {
      isLoading: getAllUpsellProductsLoading, //cell skeletons and loading overlay
      // showProgressBars: getUserLoading, //progress bars while refetching
      // isSaving: getUserLoading, //progress bars and save button spinners
    },
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: false,
    enableFacetedValues: true,
    enableRowActions: true,
    enableRowSelection: false,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [5, 10, 20],
      shape: "rounded",
      variant: "outlined",
    },
    // renderDetailPanel: ({ row }) => (
    //   <Box
    //     sx={{
    //       alignItems: "center",
    //       display: "flex",
    //       justifyContent: "space-around",
    //       left: "30px",
    //       maxWidth: "1000px",
    //       position: "sticky",
    //       width: "100%",
    //     }}
    //   >
    //     <Box sx={{ textAlign: "center" }}>
    //       <Typography variant="h4">Signature Catch Phrase:</Typography>
    //       <Typography variant="h1">
    //         &quot;{row.original.signatureCatchPhrase}&quot;
    //       </Typography>
    //     </Box>
    //   </Box>
    // ),
    renderRowActionMenuItems: ({
      closeMenu,
      row,
    }: {
      closeMenu: any;
      row: any;
    }) => [
      <MenuItem
        key={1}
        onClick={() => {
          setSelectedUpsellProductData(row.original);
          setOpenUpdateUpsellProductDetailsDialog(true);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <UpdateIcon />
        </ListItemIcon>
        Update Upsell Product Details
      </MenuItem>,
      <MenuItem
        key={3}
        onClick={() => {
          setSelectedUpsellProductData(row.original);
          setOpenUpdateUpsellProductImageDialog(true);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <UpdateIcon />
        </ListItemIcon>
        Update Upsell Product Image
      </MenuItem>,
      row?.original?.status_name !== "active" && (
        <MenuItem
          key={4}
          onClick={() => {
            handleEnableUpsellProduct(row.original);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Enable Upsell Product
        </MenuItem>
      ),
      row?.original?.status_name === "active" && (
        <MenuItem
          key={5}
          onClick={() => {
            handleDisableUpsellProduct(row.original);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Disable Upsell Product
        </MenuItem>
      ),
    ],
  });

  const handleCloseAddUpsellProduct = (message: string) => {
    setOpenAddUpsellProductDialog(false);
    if (message !== "") {
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      refetch();
    }
  };

  const handleEnableUpsellProduct = (upsellData: any) => {
    dispatch(
      enableUpsellProduct({
        upsell_product_id: upsellData?.upsell_product_id,
      })
    ).then((enableUpsellProductResult: any) => {
      setSnackbarMessage(enableUpsellProductResult?.payload?.message);
      setOpenSnackbar(true);
      refetch();
    });
  };

  const handleDisableUpsellProduct = (upsellData: any) => {
    dispatch(
      disableUpsellProduct({
        upsell_product_id: upsellData?.upsell_product_id,
      })
    ).then((disableUpsellProductResult: any) => {
      setSnackbarMessage(disableUpsellProductResult?.payload?.message);
      setOpenSnackbar(true);
      refetch();
    });
  };

  const handleCloseUpdateUpsellProductDetails = (message: string) => {
    setOpenUpdateUpsellProductDetailsDialog(false);
    if (message !== "") {
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      refetch();
    }
    setSelectedUpsellProductData(null);
  };

  const handleCloseUpdateUpsellProductImage = (message: string) => {
    setOpenUpdateUpsellProductImageDialog(false);
    if (message !== "") {
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      refetch();
    }
    setSelectedUpsellProductData(null);
  };

  useEffect(() => {
    dispatch(getUpsellProductTypes({}));
    dispatch(getUpsellProductIntervals({}));
    dispatch(
      getAllAccounts({
        account_id: userInfo?.account_id,
      })
    );
  }, []);

  return (
    <Grid container>
      <Spinner loading={getAllUpsellProductsLoading} />
      <Spinner loading={enableUpsellProductLoading} />
      <Spinner loading={disableUpsellProductLoading} />
      <Grid container item xs={12} sx={{ padding: "20px 10px 20px 10px" }}>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => setOpenAddUpsellProductDialog(true)}
        >
          Add Upsell Product
        </LVLpmThemeButton>
      </Grid>
      <Grid container item xs={12} sx={{ padding: "20px 10px 20px 10px" }}>
        <TableTemplate tableData={upsellTable} />
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        open={openSnackbar}
        message={snackbarMessage}
        key={"topcenter"}
        onClose={() => setOpenSnackbar(false)}
      />
      <AddUpsellProductDialog
        open={openAddUpsellProductDialog}
        handleCloseAddUpsellProduct={(message: string) =>
          handleCloseAddUpsellProduct(message)
        }
      />
      <UpdateUpsellProductDetailsDialog
        open={openUpdateUpsellProductDetailsDialog}
        handleCloseUpdateUpsellProductDetails={(message: string) =>
          handleCloseUpdateUpsellProductDetails(message)
        }
        selectedUpsellProductData={selectedUpsellProductData}
      />

      <UpdateUpsellProductImageDialog
        open={openUpdateUpsellProductImageDialog}
        handleCloseUpdateUpsellProductImage={(message: string) =>
          handleCloseUpdateUpsellProductImage(message)
        }
        selectedUpsellProductData={selectedUpsellProductData}
      />
    </Grid>
  );
};

export default UpsellManagement;
