import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { valueFormatter } from "../../utilities/utils";

const online = [
  4000, 3000, 2000, 2780, 1890, 2390, 3490, 1890, 2390, 3490, 1890, 2390,
];
const store = [
  2400, 1398, 9800, 3908, 4800, 3800, 4300, 4800, 3800, 4300, 4800, 3800,
];
const xLabels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dev",
];

const RevenueChart = (props) => {
  const { data } = props;
  return (
    <BarChart
      dataset={data ? data : []}
      height={450}
      series={[
        {
          dataKey: "gross_monthly_income",
          label: "Gross Monthly Income",
          valueFormatter: (value) => valueFormatter(value),
        },
        {
          dataKey: "gross_monthly_expense",
          label: "Gross Monthly Expenses",
          valueFormatter: (value) => valueFormatter(value),
          stack: "total",
        },
        {
          dataKey: "gross_monthly_payout",
          label: "Net Monthly Profit",
          valueFormatter: (value) => valueFormatter(value),
          stack: "total",
        },
      ]}
      xAxis={[{ dataKey: "month", scaleType: "band" }]}
    />
  );
};

export default RevenueChart;
