import { useEffect, useMemo, useState } from "react";
import { Box, Grid } from "@mui/material";

const SubscriptionPage = () => {
  return (
    <Grid
      component="div"
      container
      sx={{
        width: "100%",
        padding: "15px",
      }}
    >
      Subscribe
    </Grid>
  );
};

export default SubscriptionPage;
