import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { tokenInterceptor } from "../../utilities/authMidleware";
import { backendURL } from "../../utilities/utils";
import { displayConsoleError } from "../../utilities/helperFunctions";

const subscribeRoute = "/api/v1/subscribe";

const addNewSubscription = createAsyncThunk(
  "subscribe/addNewSubscription",
  async ({ email }: { email: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${subscribeRoute}/addNewSubscription`,
        { email: email },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("addNewSubscription", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export { addNewSubscription };
