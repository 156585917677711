import { Box, Container, Typography } from "@mui/material";
import React from "react";

const MobileTimeLine = () => {
  return (
    <Container maxWidth="lg" sx={{ textAlign: "center" }}>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{ backgroundColor: "#A6F5FF", height: "5px", width: "45%" }}
          ></Box>
          <Box
            sx={{
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              backgroundColor: "#A6F5FF",
              position: "relative",
            }}
          >
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: "#1CE5FF",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            ></Box>
          </Box>
          <Box
            sx={{ backgroundColor: "#A6F5FF", height: "5px", width: "45%" }}
          ></Box>
        </Box>
        <Typography
          variant="body1"
          sx={{ fontSize: "1.2rem", maxWidth: "85%", margin: "25px auto" }}
        >
          At LVLpm, we specialize in providing hotel-quality experiences through
          curated, well-designed properties. Every stay is crafted to offer the
          perfect blend of comfort, luxury, and local flavor, ensuring guests
          feel at home while experiencing something extraordinary.
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{ backgroundColor: "#A6F5FF", height: "5px", width: "45%" }}
          ></Box>
          <Box
            sx={{
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              backgroundColor: "#A6F5FF",
              position: "relative",
            }}
          >
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: "#1CE5FF",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            ></Box>
          </Box>
          <Box
            sx={{ backgroundColor: "#A6F5FF", height: "5px", width: "45%" }}
          ></Box>
        </Box>
        <Typography
          variant="body1"
          sx={{ fontSize: "1.2rem", maxWidth: "85%", margin: "25px auto" }}
        >
          Our platform empowers property owners with automated, stress-free
          management solutions. With a focus on transparency, automation, and
          smart integrations, owners unlock sustainable income with minimal
          effort while maintaining complete visibility over their assets.
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{ backgroundColor: "#A6F5FF", height: "5px", width: "45%" }}
          ></Box>
          <Box
            sx={{
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              backgroundColor: "#A6F5FF",
              position: "relative",
            }}
          >
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: "#1CE5FF",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            ></Box>
          </Box>
          <Box
            sx={{ backgroundColor: "#A6F5FF", height: "5px", width: "45%" }}
          ></Box>
        </Box>
        <Typography
          variant="body1"
          sx={{ fontSize: "1.2rem", maxWidth: "85%", margin: "25px auto" }}
        >
          Our partnerships with local vendors allow us to offer personalized
          concierge services, including in-app guidebooks, exclusive local
          recommendations, and add-on services such as private chefs,
          excursions, and wellness experiences.
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{ backgroundColor: "#A6F5FF", height: "5px", width: "45%" }}
          ></Box>
          <Box
            sx={{
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              backgroundColor: "#A6F5FF",
              position: "relative",
            }}
          >
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: "#1CE5FF",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            ></Box>
          </Box>
          <Box
            sx={{ backgroundColor: "#A6F5FF", height: "5px", width: "45%" }}
          ></Box>
        </Box>
        <Typography
          variant="body1"
          sx={{ fontSize: "1.2rem", maxWidth: "85%", margin: "25px auto" }}
        >
          With a vision to become the global leader in experiential hospitality,
          LVLpm continually innovates to offer more ways to earn and enjoy.
          Whether through unique in-home amenities, immersive travel
          experiences, or next-generation concierge services, we’re redefining
          what it means to feel at home—anywhere.
        </Typography>
      </Box>
    </Container>
  );
};

export default MobileTimeLine;
