import { createSlice } from "@reduxjs/toolkit";
import { Property } from "../../../types/propertiesTypes";
import {
  getAllPropertyTypes,
  getPropertySpecification,
  getPropertyType,
  getPropertiesListings,
  getHomeProperties,
} from "../../thunks/listingsThunk";
import dayjs from "dayjs";

interface ListingsState {
  listingLoading: Boolean;
  allProperties: any[] | null;
  allPropertyTypes: any[] | null;

  selectedPropertyDetails: Property;
  allDestination: any[];

  currentDestination?: object | null;

  homeLocation: any[];

  searchPropertyName?: string;
  selectedPropertyType?: object[];
  selectedPrice?: object[];
  selectedAmenities?: object[];

  error: any;
  response: any;
  success: Boolean;
}
const initialState: ListingsState = {
  listingLoading: false,
  allProperties: [],
  allPropertyTypes: [],

  searchPropertyName: "",
  allDestination: [],
  selectedPropertyType: [],
  selectedPrice: [],
  homeLocation: [],
  selectedPropertyDetails: {
    property_id: "",
    property_name: "",
    guests: "",
    description: "",
    instant_booking: true,
    rooms: "",
    extra: "",
    rules: "",
    bedrooms: "",
    bathrooms: "",
    property_images_url: "",
  },
  currentDestination: {
    destination: "Anywhere",
    value: null,
    guests: 2,
    checkIn: dayjs(),
    checkOut: dayjs().add(3, "day"),
  },
  error: null,
  response: null,
  success: false,
};
export const listingsSlice = createSlice({
  name: "listings",
  initialState,
  reducers: {
    resetListings: (state) => {},
    setSelectedProperty: (state, action) => {
      state.selectedPropertyDetails = {
        ...state.selectedPropertyDetails,
        ...action.payload,
      };
    },
    setCurrentDestination: (state, action) => {
      state.currentDestination = {
        ...state.currentDestination,
        ...action.payload,
      };
    },
    setSelectedPropertiesType: (state, action) => {
      state.selectedPropertyType = action.payload;
    },
    setSelectedPrices: (state, action) => {
      state.selectedPrice = action.payload;
    },
    setSearchPropertyName: (state, action) => {
      state.searchPropertyName = action.payload;
    },
  },
  extraReducers(builder) {
    // Get All Properties
    builder.addCase(getPropertiesListings.pending, (state) => {
      state.listingLoading = true;
      state.error = null;
    });
    builder.addCase(getPropertiesListings.fulfilled, (state, action) => {
      state.allProperties = action.payload;
      state.listingLoading = false;
    });
    builder.addCase(getPropertiesListings.rejected, (state, action) => {
      state.error = action.payload;
      state.listingLoading = false;
    });
    // Get All Property Types
    builder.addCase(getAllPropertyTypes.pending, (state) => {
      state.listingLoading = true;
      state.error = null;
    });
    builder.addCase(getAllPropertyTypes.fulfilled, (state, action) => {
      state.allPropertyTypes = action.payload.all_property_types;
      state.listingLoading = false;
    });
    builder.addCase(getAllPropertyTypes.rejected, (state, action) => {
      state.listingLoading = false;
      state.error = action.payload;
    });
    // Get Property Specifications
    builder.addCase(getPropertySpecification.pending, (state) => {
      state.listingLoading = true;
      state.error = null;
    });
    builder.addCase(getPropertySpecification.fulfilled, (state, action) => {
      state.selectedPropertyDetails = {
        ...state.selectedPropertyDetails,
        propertySpecifications: action.payload,
      };
      state.listingLoading = false;
    });
    builder.addCase(getPropertySpecification.rejected, (state, action) => {
      state.error = action.payload;
      state.listingLoading = false;
    });
    // Get Home Properties
    builder.addCase(getHomeProperties.pending, (state) => {
      state.listingLoading = true;
      state.error = null;
    });
    builder.addCase(getHomeProperties.fulfilled, (state, action) => {
      state.homeLocation = action.payload.home_location;
      state.listingLoading = false;
    });
    builder.addCase(getHomeProperties.rejected, (state, action) => {
      state.error = action.payload;
      state.listingLoading = false;
    });
  },
});

export const {
  setSelectedProperty,
  setCurrentDestination,
  setSelectedPropertiesType,
  setSelectedPrices,
  setSearchPropertyName,
  resetListings,
} = listingsSlice.actions;

export const listingsReducer = listingsSlice.reducer;
