import { Box, Button, Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import AccountDrawer from "../../components/commonComponents/accountDrawer/accountDrawer";
import UserManagement from "./userManagement/usermanagement";

import AddressManagement from "./addressManagement/addressManagement";
import PropertyManagement from "./propertyManagement/propertymanagement";

import BookingManagement from "./bookingManagement/bookingManagement";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import UpsellManagement from "./upsellManagement/upsellManagement";

const userQueryClient = new QueryClient();
const bookingQueryClient = new QueryClient();
const propertyQueryClient = new QueryClient();
const upsellQueryClient = new QueryClient();
const AdminPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { userInfo, loggedIn } = useAppSelector(
    (state: any) => state.authentication
  );

  const [selectedNavigation, setSelectedNavigation] = useState("users");

  const [open, setOpen] = useState(false);

  const handleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    } else {
      if (userInfo?.account_id > 2) {
        setSelectedNavigation("users");
      } else {
        navigate("/dashboard");
      }
    }
  }, []);

  const handleSystemNavigation = (navSelection: any) => {
    setSelectedNavigation(navSelection);
  };

  return (
    <Box
      sx={{
        padding: "0 10px 0 60px",
        width: "100%",
      }}
    >
      <AccountDrawer handleDrawer={handleDrawer} open={open} />
      <Grid item sx={{ flexGrow: 1, p: 1 }}>
        <Grid
          container
          item
          xs={12}
          sx={{
            backgroundColor: "#00B9D1",
            borderTop: "5px solid white",
            // height: "40px",
            display: "flex",
            flexDirection: "row",
            // minWidth: "400px",
            padding: "5px",
          }}
        >
          <Button
            onClick={() => {
              handleSystemNavigation("users");
            }}
            sx={{
              color: "white !important",
              padding: "0px 10px 0px 10px !important",
              textDecoration: selectedNavigation === "users" ? "underline" : "",
            }}
          >
            Users
          </Button>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ color: "white", backgroundColor: "white" }}
          />
          <Button
            onClick={() => {
              handleSystemNavigation("properties");
            }}
            sx={{
              color: "white !important",
              padding: "0px 10px 0px 10px !important",
              textDecoration:
                selectedNavigation === "properties" ? "underline" : "",
            }}
          >
            Properties
          </Button>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ color: "white", backgroundColor: "white" }}
          />
          <Button
            onClick={() => {
              handleSystemNavigation("bookings");
            }}
            sx={{
              color: "white !important",
              padding: "0px 10px 0px 10px !important",
              textDecoration:
                selectedNavigation === "bookings" ? "underline" : "",
            }}
          >
            Bookings
          </Button>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ color: "white", backgroundColor: "white" }}
          />
          <Button
            onClick={() => {
              handleSystemNavigation("upsells");
            }}
            sx={{
              color: "white !important",
              padding: "0px 10px 0px 10px !important",
              textDecoration:
                selectedNavigation === "upsells" ? "underline" : "",
            }}
          >
            Upsells
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            height: "100vh",
          }}
        >
          {selectedNavigation === "users" && (
            <QueryClientProvider client={userQueryClient}>
              <UserManagement />
            </QueryClientProvider>
          )}
          {selectedNavigation === "properties" && (
            <QueryClientProvider client={propertyQueryClient}>
              <PropertyManagement />{" "}
            </QueryClientProvider>
          )}
          {selectedNavigation === "bookings" && (
            <QueryClientProvider client={bookingQueryClient}>
              <BookingManagement />
            </QueryClientProvider>
          )}
          {selectedNavigation === "upsells" && (
            <QueryClientProvider client={upsellQueryClient}>
              <UpsellManagement />
            </QueryClientProvider>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminPage;
