import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

interface OverviewCardProps {
  title: string;
  icon: any;
  profit: any;
}

const OverviewCard = (props: OverviewCardProps) => {
  const { title, icon, profit } = props;
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card sx={{ width: "100%", backgroundColor: "#F2EEFE" }}>
        <CardContent>
          <Typography variant="h5" component="div"></Typography>
          <Typography sx={{}}>{title}</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "end",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {profit}
            </Typography>
            <img src={icon} />
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default OverviewCard;
