import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import AddressEditForm from "./AddressEditForm";

interface ModalProps {
  open: boolean;
  handleClose: any;
  addressInfo: any;
}

const AddressEditModal = (props: ModalProps) => {
  const { open, handleClose, addressInfo } = props;

  const matchesX = useMediaQuery("(min-width:1100px)");
  const matchesY = useMediaQuery("(min-height:1200px)");

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      scroll="paper"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Edit Address
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <AddressEditForm addressInfo={addressInfo} />
      </DialogContent>
    </Dialog>
  );
};

export default AddressEditModal;
