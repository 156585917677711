import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox, Chip, FormControlLabel, FormGroup } from "@mui/material";
import dayjs from "dayjs";

const GuestListChart = (props: any) => {
  const { bookings } = props;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#E2F6FC" }}>
            <TableCell sx={{ fontWeight: "bold" }} align="right">
              Guest
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">
              Platform
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">
              Check-In/Out
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">
              Gross Booking
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">
              Discount
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">
              Tax
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">
              LVLpm Fee
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">
              Net Booking
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bookings?.length > 0 ? (
            bookings.map((row: any, index: number) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="right" scope="row">
                  {`${row?.first_name} ${row?.last_name}`}
                </TableCell>
                <TableCell align="right">
                  <Chip
                    label={row?.booking_location_label}
                    sx={{
                      color:
                        row?.booking_location_id === "Airbnb"
                          ? "#DC0D0D"
                          : row?.booking_location_id === "LVLpm"
                          ? "#00B9D1"
                          : row?.booking_location_id === "VRBO"
                          ? "#0146F8"
                          : "",
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  {dayjs(new Date(row?.check_in)).format("ddd DD, MMM YY")} /
                  {dayjs(new Date(row?.check_out)).format("ddd DD, MMM YY")}
                </TableCell>
                <TableCell align="right">${row?.total_price}</TableCell>
                <TableCell align="right">${0}</TableCell>
                <TableCell align="right">${row?.taxes}</TableCell>
                <TableCell align="right">${row?.lvlpm_fee}</TableCell>
                <TableCell align="right">${row?.netBooking}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow style={{ display: "flex", justifyContent: "center" }}>
              <TableCell align="center" scope="row">
                No bookings for this month yet
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GuestListChart;
