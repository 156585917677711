import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Spinner from "../../../../components/reusableComponents/spinner/spinner";
import Grid from "@mui/material/Grid";
import {
  Box,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { LVLpmThemeButton } from "../../../../utilities/constants";
import { updateUpsellProductImage } from "../../../../store/thunks/upsellThunk";
import { displayConsoleError } from "../../../../utilities/helperFunctions";

interface UpdateUpsellProductImageDialogProps {
  open: boolean;
  handleCloseUpdateUpsellProductImage: (message: string) => void;
  selectedUpsellProductData: any;
}

const UpdateUpsellProductImageDialog = (
  props: UpdateUpsellProductImageDialogProps
) => {
  const {
    open,
    handleCloseUpdateUpsellProductImage,
    selectedUpsellProductData,
  } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { updateUpsellProductImageLoading } = useAppSelector(
    (state: any) => state.upsell
  );

  const [upsellProductImage, setUpsellProductImage] = useState<any>();

  const [updateUpsellProductImageError, setUpdateUpsellProductImageError] =
    useState(false);
  const [
    updateUpsellProductImageErrorMessage,
    setUpdateUpsellProductImageErrorMessage,
  ] = useState("");

  const closeUpdateUpsellProductImageDialog = (event: any) => {
    setUpdateUpsellProductImageError(false);
    setUpdateUpsellProductImageErrorMessage("");
    handleCloseUpdateUpsellProductImage(event);
  };

  const handleSubmitUpdateUpsellProductImage = () => {
    const upsellProductImagePayload = {
      images: upsellProductImage,
      upsell_product_id: selectedUpsellProductData?.upsell_product_id,
      upsell_product_image_path:
        selectedUpsellProductData?.upsell_product_image_path,
    };

    dispatch(
      updateUpsellProductImage({
        upsell_product_image_payload: upsellProductImagePayload,
      })
    )
      .then((response: any) => {
        if (response.payload.result) {
          closeUpdateUpsellProductImageDialog(response.payload.message);
        } else {
          setUpdateUpsellProductImageError(true);
          setUpdateUpsellProductImageErrorMessage(response.payload.message);
        }
      })
      .catch((error: any) => {
        displayConsoleError("updateUpsellProductImage", error);
      });
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth={true} scroll="paper">
      <Spinner loading={updateUpsellProductImageLoading} />
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Update Product Image
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            closeUpdateUpsellProductImageDialog("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <Grid container>
          <Grid item xs={6} sx={{ padding: "1%" }}>
            <TextField
              id="upsell_product_name"
              label="Product Name"
              name="upsell_product_name"
              value={selectedUpsellProductData?.upsell_product_name}
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
              disabled
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: "1%" }}>
            <Box
              sx={{
                display: "flex",
                overflowX: "auto",
                maxWidth: "100%",
              }}
            >
              <img
                src={selectedUpsellProductData?.upsell_product_image_url}
                style={{
                  width: "150px",
                  height: "150px",
                  marginRight: "10px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ padding: "1%" }}>
            <TextField
              type="file"
              id="upsell_product_image"
              name="upsell_product_image"
              inputProps={{
                accept: "image/*",
              }}
              onChange={(event: any) => {
                if (event.target.files[0]) {
                  const filelist: any[] = Array.from(event.target.files);
                  setUpsellProductImage(filelist);
                } else {
                  setUpsellProductImage(null);
                }
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: "1%" }}>
            {upsellProductImage && upsellProductImage?.length > 0 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    overflowX: "auto",
                    maxWidth: "100%",
                  }}
                >
                  {upsellProductImage?.map((image: any, index: number) => (
                    <img
                      key={index}
                      src={URL.createObjectURL(image)}
                      style={{
                        width: "150px",
                        height: "150px",
                        marginRight: "10px",
                      }}
                    />
                  ))}
                </Box>
              </>
            )}
          </Grid>
          {updateUpsellProductImageError && (
            <Grid item xs={12} sx={{ padding: "1%", justifyContent: "center" }}>
              <Typography>{updateUpsellProductImageErrorMessage}</Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <LVLpmThemeButton
          variant="contained"
          onClick={(event: any) => closeUpdateUpsellProductImageDialog("")}
        >
          Cancel
        </LVLpmThemeButton>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => handleSubmitUpdateUpsellProductImage()}
          disabled={!upsellProductImage || upsellProductImage?.length === 0}
        >
          Submit
        </LVLpmThemeButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateUpsellProductImageDialog;
