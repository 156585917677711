import { SyntheticEvent, useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { LVLpmThemeButton } from "../../../../utilities/constants";
import Spinner from "../../../../components/reusableComponents/spinner/spinner";
import { addPropertyExpense } from "../../../../store/thunks/propertyThunk";
import { displayConsoleError } from "../../../../utilities/helperFunctions";

interface AddPropertyExpenseDialogProps {
  open: boolean;
  handleCloseCancel: (event: any) => void;
  selectedPropertyDetails: any;
}

const AddPropertyExpenseDialog = (props: AddPropertyExpenseDialogProps) => {
  const { open, handleCloseCancel, selectedPropertyDetails } = props;

  const dispatch = useAppDispatch();

  const { addPropertyExpenseLoading } = useAppSelector(
    (state: any) => state.property
  );

  const [enableAddPropertyExpense, setEnableAddPropertyExpense] =
    useState(true);

  const [addPropertyExpenseError, setAddPropertyExpenseError] = useState(false);
  const [addPropertyExpenseErrorMessage, setAddPropertyExpenseErrorMessage] =
    useState("");

  const [propertyExpenseType, setPropertyExpenseType] = useState("");
  const [propertyExpenseAmount, setPropertyExpenseAmount] = useState("");

  const handleCloseAddPropertyExpenseDialog = (event: any) => {
    setAddPropertyExpenseError(false);
    setAddPropertyExpenseErrorMessage("");
    setPropertyExpenseType("");
    setPropertyExpenseAmount("");
    handleCloseCancel(event);
  };

  const submitAddPropertyExpenseDialog = () => {
    setAddPropertyExpenseError(false);
    setAddPropertyExpenseErrorMessage("");
    dispatch(
      addPropertyExpense({
        property_expense_payload: {
          property_id: selectedPropertyDetails?.property_id,
          expense_type: propertyExpenseType,
          expense_amount: propertyExpenseAmount,
        },
      })
    )
      .then((addPropertyExpenseResult) => {
        if (addPropertyExpenseResult?.payload?.status) {
          handleCloseAddPropertyExpenseDialog(
            addPropertyExpenseResult?.payload?.message
          );
        } else {
          setAddPropertyExpenseError(true);
          setAddPropertyExpenseErrorMessage(
            "Unable to add property expense, Please try again later or contact support"
          );
        }
      })
      .catch((addPropertyExpenseError) => {
        displayConsoleError("addPropertyExpense", addPropertyExpenseError);
        setAddPropertyExpenseError(true);
        setAddPropertyExpenseErrorMessage(
          "Unable to add property expense, Please try again later or contact support"
        );
      });
  };

  const checkEnableAddPropertyExpense = () => {
    if (propertyExpenseType !== "" && propertyExpenseAmount !== "") {
      setEnableAddPropertyExpense(false);
    } else {
      setEnableAddPropertyExpense(true);
    }
  };

  useEffect(() => {
    checkEnableAddPropertyExpense();
  }, [propertyExpenseType, propertyExpenseAmount]);

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true}>
      <Spinner loading={addPropertyExpenseLoading} />
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Update User Account
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleCloseAddPropertyExpenseDialog("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <Grid sx={{ height: "300px" }}>
          <Grid container>
            <Grid item xs={6} sx={{ padding: "1%" }}>
              <TextField
                id="property_name"
                label="Property Name"
                name="property_name"
                value={selectedPropertyDetails?.property_name}
                sx={{
                  width: "100%",
                  marginBottom: "5px",
                }}
                disabled
              />
            </Grid>
            <Grid item xs={6} sx={{ padding: "1%" }}>
              <TextField
                id="address"
                label="Address"
                name="address"
                value={selectedPropertyDetails?.full_address}
                sx={{
                  width: "100%",
                  marginBottom: "5px",
                }}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} sx={{ padding: "1%" }}>
              <TextField
                id="expense_type"
                label="Expense Type"
                name="expense_type"
                value={propertyExpenseType}
                onChange={(event) => setPropertyExpenseType(event.target.value)}
                sx={{
                  width: "100%",
                  marginBottom: "5px",
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ padding: "1%" }}>
              <TextField
                id="expense_amount"
                label="Expense Amount"
                name="expense_amount"
                type="number"
                InputProps={{
                  inputProps: { min: 0 },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={propertyExpenseAmount}
                onChange={(event) =>
                  setPropertyExpenseAmount(event.target.value)
                }
                sx={{
                  width: "100%",
                  marginBottom: "5px",
                }}
                // error={selectedPropertyExpenseAmount === "" ? true : false}
                // helperText={
                //   selectedPropertyExpenseAmount === ""
                //     ? "Expense Amount is required."
                //     : ""
                // }
              />
            </Grid>
          </Grid>
          {addPropertyExpenseError && (
            <Grid
              container
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Typography sx={{ color: "red" }}>
                {addPropertyExpenseErrorMessage}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => handleCloseAddPropertyExpenseDialog("")}
        >
          Cancel
        </LVLpmThemeButton>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => submitAddPropertyExpenseDialog()}
          disabled={enableAddPropertyExpense}
        >
          Submit
        </LVLpmThemeButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddPropertyExpenseDialog;
