import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "react-multi-carousel/lib/styles.css";
import Footer from "../../components/commonComponents/footer/footer";
import {
  dbKeyConverterToLabel,
  specificationsIcons,
} from "../../utilities/utils";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import * as Yup from "yup";
import { useFormik } from "formik";
import { setCurrentDestination, setPreviousLocation } from "../../store";
import { loadStripe } from "@stripe/stripe-js";
import PaymentDialog from "../../components/dialogComponents/paymentDialog/paymentDialog";
import LoginRequiredDialog from "../../components/dialogComponents/loginRequiredDialog/loginRequiredDialog";
import { checkLeads } from "../../store/thunks/paymentThunk";
import PropertyBookingDialog from "../../components/dialogComponents/propertyBooking/propertyBooking";
import RatingStar from "../../components/reusableComponents/ratingStar/ratingStar";
import {
  dateRangeOverlap,
  displayConsoleError,
} from "../../utilities/helperFunctions";
import { getPropertyBookings } from "../../store/thunks/bookingsThunk";
import { LVLpmThemeButtonOther } from "../../utilities/constants";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import { enGB } from "date-fns/locale";
import UserVerificationDialog from "../../components/dialogComponents/userVerificationDialog/userVerificationDialog";
import { useJsApiLoader } from "@react-google-maps/api";
import CloseIcon from "@mui/icons-material/Close";
import CalendarView from "../../components/reusableComponents/calendarView/calendarView";

const stripePromise = loadStripe(
  "pk_test_51NO2UgEC8YRyVLJi8TjlK60HOfzNYsZ1iDEBwDytWFqED5NKq3ot7WcNJn8chykMUkYUVgrIjD2hUuDGuqFhtZf2009do8353y"
);

const ListingDetails = () => {
  const { propertyId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedPropertyDetails, currentDestination } = useAppSelector(
    (state: any) => state.listings
  );

  const { loggedIn, userInfo } = useAppSelector(
    (state: any) => state.authentication
  );

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [activeAmenities, setActiveAmenities] = useState<string[]>([]);
  const [allActiveAmenities, setAllActiveAmenities] = useState<string[]>([]);

  const [propertyLeads, setPropertyLeads] = useState<object>({});

  const [nights, setNights] = useState<any>(0);

  const [pricePerDay, setPricePerDay] = useState<any>(0);
  const [stayPrice, setStayPrice] = useState<any>(0);
  const [lvlpmFee, setLvlpmFee] = useState<any>(0);
  const [taxes, setTaxes] = useState<any>(0);
  const [totalPrice, setTotalPrice] = useState<any>(0);

  const [openDescription, setOpenDescription] = useState(false);
  const [openAmenities, setOpenAmenities] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [openLoginRequiredDialog, setOpenLoginRequiredDialog] = useState(false);
  const [openUserVerificationDialog, setOpenUserVerificationDialog] =
    useState(false);
  const [openPropertyBookingDialog, setOpenPropertyBookingDialog] =
    useState(false);

  const [existingBookings, setExistingBookings] = useState<any[]>([]);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBr_kgPXvcnTQ1w4DP_UvSy-fVUa1QuT8s",
    libraries: ["places"],
  });

  const handleOpenAmenities = () => {
    setOpenAmenities(true);
  };

  const handleCloseAmenities = () => {
    setOpenAmenities(false);
  };

  const activeAmenitiesView = () => {
    let tempActiveAmenities = [];
    setAllActiveAmenities(
      Object.keys(selectedPropertyDetails?.propertySpecifications).filter(
        (key) => selectedPropertyDetails?.propertySpecifications[key] === true
      )
    );

    let amenitiesKeys = Object.keys(
      selectedPropertyDetails?.propertySpecifications
    ).sort(() => 0.5 - Math.random());

    for (const key of amenitiesKeys) {
      if (selectedPropertyDetails?.propertySpecifications[key] === true) {
        if (tempActiveAmenities.length < 8) {
          tempActiveAmenities.push(key);
        }
      }
    }
    setActiveAmenities(tempActiveAmenities);
  };

  const reservationInitialValues = {
    guests: 2,
    checkIn: new Date(),
    checkOut: new Date().getDate() + 1,
    amount: 0,
    cardNumber: "",
    cardHolder: "",
    expidation: "",
    ccv: "",
  };

  const today = new Date();
  today.setDate(today.getDate() - 1);

  const validateReservation: any = Yup.object({
    guests: Yup.number().integer().min(1).max(10).required(),
    checkIn: Yup.date().min(today, "Please choose future date"),
    checkOut: Yup.date().when(
      "checkIn",
      (checkIn, schema) => checkIn && schema.min(checkIn)
    ),
    amount: Yup.number().integer().min(0).required(),
  });

  const handleOpenPaymentDialog = () => {
    if (loggedIn) {
      // if (userInfo?.verified) {
      const current_day: any = dayjs(new Date());

      const checkIn: any =
        typeof reservationForm.values?.checkIn === "string"
          ? dayjs(new Date(reservationForm.values?.checkIn))
          : reservationForm.values?.checkIn;
      const checkOut: any =
        typeof reservationForm.values?.checkOut === "string"
          ? dayjs(new Date(reservationForm.values?.checkOut))
          : reservationForm.values?.checkOut;

      if (checkIn <= current_day) {
        setSnackbarMessage(
          "Please Select a future date. Contact Support for same day booking!"
        );
        setOpenSnackbar(true);
        return;
      }

      const number_of_days =
        checkOut && checkIn
          ? checkOut > checkIn
            ? Math.round((checkOut - checkIn) / (1000 * 60 * 60 * 24))
            : 0
          : 0;

      if (number_of_days < 3) {
        setSnackbarMessage(
          "A minimum of 3 nights is required. Please adjust your booking dates!"
        );
        setOpenSnackbar(true);
        return;
      }

      const leadsInfo: any = {
        hostfully_id: selectedPropertyDetails?.hostfully_property_id,
        lead_range: [
          {
            check_in: checkIn.format("YYYY-MM-DD"),
            check_out: checkOut.format("YYYY-MM-DD"),
          },
        ],
      };

      dispatch(checkLeads({ leadsInfo }))
        .then((leadResult: any) => {
          if (
            leadResult.payload?.leads &&
            leadResult.payload?.leads.length > 0
          ) {
            let leadChecking: Boolean = true;
            leadResult.payload?.leads.forEach((currentLeads: any) => {
              if (["NEW", "PENDING", "BOOKED"].includes(currentLeads?.status)) {
                const check = dateRangeOverlap(
                  [checkIn, checkOut],
                  [
                    dayjs(new Date(currentLeads?.checkInZonedDateTime)),
                    dayjs(new Date(currentLeads?.checkOutZonedDateTime)),
                  ]
                );
                if (check === false) {
                  leadChecking = false;
                  setPropertyLeads({
                    check_in: currentLeads?.checkInZonedDateTime,
                    check_out: currentLeads?.checkOutZonedDateTime,
                  });
                  setOpenPropertyBookingDialog(true);
                  return;
                }
              }
            });
            if (leadChecking) {
              dispatch(
                getPropertyBookings({
                  property_id: selectedPropertyDetails?.property_id,
                })
              )
                .then((result: any) => {
                  if (
                    result.payload?.property_bookings &&
                    result.payload?.property_bookings.length > 0
                  ) {
                    let checking: Boolean = true;
                    result.payload?.property_bookings.forEach(
                      (property_booking: any) => {
                        const check = dateRangeOverlap(
                          [checkIn, checkOut],
                          [
                            dayjs(new Date(property_booking?.check_in)),
                            dayjs(new Date(property_booking?.check_out)),
                          ]
                        );
                        if (check === false) {
                          checking = false;
                          setPropertyLeads({
                            check_in: property_booking?.check_in,
                            check_out: property_booking?.check_out,
                          });
                          setOpenPropertyBookingDialog(true);
                          return;
                        }
                      }
                    );
                    if (checking) setOpenPaymentDialog(true);
                  } else {
                    setOpenPaymentDialog(true);
                  }
                })
                .catch((error) => {
                  displayConsoleError("getPropertyBookings", error);
                  setSnackbarMessage(
                    "Unable to verify booking availability dates. Please try again later or contact customer support!"
                  );
                  setOpenSnackbar(true);
                });
            }
          } else {
            dispatch(
              getPropertyBookings({
                property_id: selectedPropertyDetails?.property_id,
              })
            )
              .then((result: any) => {
                if (
                  result.payload?.property_bookings &&
                  result.payload?.property_bookings.length > 0
                ) {
                  let checking: Boolean = true;
                  result.payload?.property_bookings.forEach(
                    (property_booking: any) => {
                      const check = dateRangeOverlap(
                        [checkIn, checkOut],
                        [
                          dayjs(new Date(property_booking?.check_in)),
                          dayjs(new Date(property_booking?.check_out)),
                        ]
                      );
                      if (check === false) {
                        checking = false;
                        setPropertyLeads({
                          check_in: property_booking?.check_in,
                          check_out: property_booking?.check_out,
                        });
                        setOpenPropertyBookingDialog(true);
                        return;
                      }
                    }
                  );
                  if (checking) setOpenPaymentDialog(true);
                } else {
                  setOpenPaymentDialog(true);
                }
              })
              .catch((error) => {
                displayConsoleError("getPropertyBookings", error);
                setSnackbarMessage(
                  "Unable to verify booking availability dates. Please try again later or contact customer support!"
                );
                setOpenSnackbar(true);
              });
          }
        })
        .catch((leadsInfoCheckingError: any) => {
          displayConsoleError("leadsInfoCheckingError", leadsInfoCheckingError);
          setSnackbarMessage(
            "Unable to verify booking availability dates. Please try again later or contact customer support!"
          );
          setOpenSnackbar(true);
        });
    } else {
      dispatch(setPreviousLocation(location.pathname));
      setOpenLoginRequiredDialog(true);
    }
  };

  const handleClosePaymentDialog = () => {
    setOpenPaymentDialog(false);
  };

  const handleCloseLoginRequiredDialog = () => {
    dispatch(setPreviousLocation(""));
    setOpenLoginRequiredDialog(false);
  };

  const handleCloseUserVerificationDialog = () => {
    dispatch(setPreviousLocation(""));
    setOpenUserVerificationDialog(false);
  };

  const handleClosePropertyBookingDialog = () => {
    setOpenPropertyBookingDialog(false);
  };

  const reservationForm = useFormik({
    enableReinitialize: false,
    initialValues: reservationInitialValues,
    validationSchema: validateReservation,
    onSubmit: (values) => {},
  });

  const setInitialPricing = () => {
    let checkIn =
      typeof currentDestination?.checkIn === "string"
        ? dayjs(new Date(currentDestination?.checkIn))
        : currentDestination?.checkIn;

    reservationForm.setFieldValue(
      "checkIn",
      checkIn?.toDate() >= today ? checkIn : today
    );
    let checkOut =
      typeof currentDestination?.checkOut === "string"
        ? dayjs(new Date(currentDestination?.checkOut))
        : currentDestination?.checkOut;

    reservationForm.setFieldValue(
      "checkOut",
      checkOut?.toDate() >= today.getDate() + 1 ? checkOut : today.getDate() + 1
    );
    let nights =
      currentDestination?.checkOut && currentDestination?.checkIn
        ? checkOut > checkIn
          ? Math.ceil((checkOut - checkIn) / (1000 * 60 * 60 * 24))
          : 0
        : 0;

    setNights(nights);

    if (selectedPropertyDetails?.state === "CA") {
      const stay_price =
        nights * Number(selectedPropertyDetails?.price_per_day);
      const lvl_fee = Math.round(stay_price * 0.03 * 100) / 100;
      setLvlpmFee(lvl_fee);
      const stay_price_with_cleaning_fee =
        stay_price + Number(selectedPropertyDetails?.cleaning_fee) + lvl_fee;
      setStayPrice(stay_price);
      const taxes =
        Math.round(stay_price_with_cleaning_fee * 0.078 * 100) / 100;
      setTaxes(taxes);
      const total_price =
        Math.round((stay_price_with_cleaning_fee + taxes) * 100) / 100;
      setTotalPrice(total_price);
    } else {
      const price_per_day =
        Math.round(
          Number(selectedPropertyDetails?.price_per_day) * 1.033 * 100
        ) / 100;

      setPricePerDay(price_per_day);
      const stay_price = Math.round(nights * price_per_day * 100) / 100;

      const lvl_fee = Math.round(stay_price * 0.03 * 100) / 100;

      setLvlpmFee(lvl_fee);
      const stay_price_with_cleaning_fee =
        stay_price + Number(selectedPropertyDetails?.cleaning_fee);

      setStayPrice(stay_price);
      const taxes =
        Math.round(stay_price_with_cleaning_fee * 0.078 * 100) / 100;
      setTaxes(taxes);

      const total_price =
        Math.round((stay_price_with_cleaning_fee + taxes) * 100) / 100;

      setTotalPrice(total_price);
    }

    dispatch(
      getPropertyBookings({
        property_id: selectedPropertyDetails?.property_id,
      })
    ).then((result: any) => {
      if (
        result.payload?.property_bookings &&
        result.payload?.property_bookings.length > 0
      ) {
        const temp_bookings: any[] = [];
        result.payload?.property_bookings.forEach((property_booking: any) => {
          const temp_booking = {
            startDate: new Date(property_booking?.check_in),
            endDate: new Date(property_booking?.check_out),
            key: "selection",
            color: "blue",
          };
          temp_bookings.push(temp_booking);
        });
        setExistingBookings(temp_bookings);
      }
    });
  };

  useEffect(() => {
    if (propertyId && propertyId !== selectedPropertyDetails?.property_id) {
      navigate("/listings");
    } else {
      activeAmenitiesView();
      setInitialPricing();
    }
  }, [currentDestination]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const matchesX = useMediaQuery("(min-width:900px)");

  return (
    <>
      <Container sx={{ padding: "50px 0" }}>
        {selectedPropertyDetails?.property_images_url?.split(",").length >
          0 && (
          <Carousel
            containerClass="carousel-container"
            itemClass="carousel-item"
            responsive={responsive}
            autoPlay={true}
            autoPlaySpeed={5000}
          >
            {selectedPropertyDetails?.property_images_url
              ?.split(",")
              .map((image_url: string, index: number) => {
                return (
                  <Box key={index} sx={{ width: "100%" }}>
                    <img
                      src={image_url}
                      style={{ width: "100%", height: "350px" }}
                    />
                  </Box>
                );
              })}
          </Carousel>
        )}
      </Container>
      <Box sx={{ paddingBottom: "20px", position: "relative" }}>
        <Box
          sx={{
            height: "400px",
            width: "100%",
            zIndex: "-1",
            backgroundImage: "linear-gradient(#D5EEF5, #fff)",
            position: "absolute",
            top: "0",
            left: "0",
          }}
        ></Box>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {selectedPropertyDetails?.property_name}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#00b9d1",
                  margin: "5px 0",
                  textTransform: "capitalize",
                }}
              >
                {selectedPropertyDetails?.city}
                {", "}
                {selectedPropertyDetails?.state
                  ? selectedPropertyDetails?.state
                  : selectedPropertyDetails?.country}
              </Typography>
              {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                <RatingStar
                  rating={selectedPropertyDetails?.review}
                  color="gold"
                />
                <Typography variant="body1" sx={{ marginLeft: "5px" }}>
                  {selectedPropertyDetails?.review} (325 Reviews)
                </Typography>
              </Box> */}
            </Grid>
            <Grid
              item
              md={7}
              sm={12}
              xs={12}
              sx={{
                backgroundColor: "#fff",
                margin: "20px 0 0 17px",
                boxShadow: "10px 5px 20px #e6e6e6",
                padding: matchesX ? "30px 100px 30px 30px" : "15px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ marginBottom: "10px", fontWeight: "bold" }}
                >
                  Orverview
                </Typography>
                <Typography variant="body1">
                  {selectedPropertyDetails?.description}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4.8} sm={12} xs={12}>
              <Box>
                <Typography
                  variant="h6"
                  sx={{ marginBottom: "10px", fontWeight: "bold" }}
                >
                  Amenities:
                </Typography>
                <Grid container>
                  {activeAmenities.map((value: any, index) => {
                    let AmenitiesIcon =
                      specificationsIcons[
                        value as keyof typeof specificationsIcons
                      ];
                    let label: string = dbKeyConverterToLabel(value);
                    return (
                      <Grid
                        item
                        md={6}
                        sm={12}
                        xs={12}
                        key={index}
                        sx={{
                          marginBottom: "10px",
                          display: "flex",
                        }}
                      >
                        <Typography variant="body1" sx={{ marginRight: "5px" }}>
                          {<img src={`${AmenitiesIcon}`} />}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ textTransform: "capitalize" }}
                        >
                          {label}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
                <LVLpmThemeButtonOther
                  variant="contained"
                  onClick={handleOpenAmenities}
                >
                  Show All Amenities
                </LVLpmThemeButtonOther>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container sx={{ marginTop: "25px" }}>
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Grid item xs={12} sx={{ marginBottom: "10px" }}>
            <Typography variant="h5">Reservation</Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", marginBottom: "5px" }}
              >
                Check In
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="checkIn"
                  value={dayjs(reservationForm.values.checkIn)}
                  onChange={(value) => {
                    reservationForm.setFieldValue("checkIn", value);
                    dispatch(setCurrentDestination({ checkIn: value }));
                  }}
                  disablePast
                  views={["year", "month", "day"]}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", marginBottom: "5px" }}
            >
              Check Out
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                name="checkOut"
                value={dayjs(reservationForm.values.checkOut)}
                onChange={(value) => {
                  reservationForm.setFieldValue("checkOut", value);
                  dispatch(setCurrentDestination({ checkOut: value }));
                }}
                disablePast
                views={["year", "month", "day"]}
                sx={{ width: "100%" }}
                minDate={dayjs(reservationForm.values.checkIn)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", marginBottom: "5px" }}
              >
                Guests
              </Typography>
              <Select
                name="guests"
                id="guest"
                value={
                  currentDestination
                    ? currentDestination?.guests
                    : reservationForm.values.guests
                }
                onChange={(event) => {
                  reservationForm.setFieldValue("guests", event.target.value);
                  dispatch(
                    setCurrentDestination({ guests: event.target.value })
                  );
                }}
                label="Guests"
                sx={{
                  padding: "10px 0",
                }}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                {Array.from({ length: 10 }, (v, k) => k + 1).map(
                  (key, index) => {
                    return (
                      <MenuItem value={key} key={index}>
                        {key}
                      </MenuItem>
                    );
                  }
                )}
                <MenuItem value={10}>{2} guests</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12} sx={{}}>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              $
              {selectedPropertyDetails?.state === "CA"
                ? selectedPropertyDetails?.price_per_day
                : pricePerDay}
              /Night
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container
        sx={{
          marginTop: "25px",
          marginBottom: "150px",
        }}
      >
        <Grid container>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              boxShadow: "10px 10px 20px #e6e6e6",
              padding: "0 0 30px 0",
              backgroundColor: "#fff",
              borderRadius: "15px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#313D41",
                borderRadius: "15px 15px 0 0",
                padding: "20px 50px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6" sx={{ color: "#fff" }}>
                Details
              </Typography>
              <Typography variant="h6" sx={{ color: "#fff" }}>
                Amount
              </Typography>
            </Box>
            <Box
              sx={{
                padding: "20px 50px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "25px",
                }}
              >
                <Typography variant="body1">
                  $
                  {selectedPropertyDetails?.state === "CA"
                    ? selectedPropertyDetails?.price_per_day
                    : pricePerDay}
                  x {nights} nights
                </Typography>
                <Typography variant="body1">
                  ${nights <= 0 ? "" : stayPrice}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "25px",
                }}
              >
                <Typography variant="body1">
                  Entire Stay Cleaning Fee
                </Typography>
                <Typography variant="body1">
                  ${nights <= 0 ? "" : selectedPropertyDetails?.cleaning_fee}
                </Typography>
              </Box>
              {selectedPropertyDetails?.state === "CA" && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "25px",
                  }}
                >
                  <Typography variant="body1">LVLPm Fee (.03%)</Typography>
                  <Typography variant="body1">
                    ${nights <= 0 ? "" : lvlpmFee}
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "25px",
                }}
              >
                <Typography variant="body1">Taxes</Typography>
                <Typography variant="body1">
                  ${nights <= 0 ? "" : taxes}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "25px",
                  borderTop: "1px solid #e6e6e6",
                  borderBottom: "1px solid #e6e6e6",
                  padding: "15px 0",
                }}
              >
                <Typography variant="h6">Total (USD)</Typography>
                <Typography variant="h6">
                  ${nights <= 0 ? "" : totalPrice}
                </Typography>
              </Box>
              <Box>
                <LVLpmThemeButtonOther
                  variant="contained"
                  onClick={() => handleOpenPaymentDialog()}
                >
                  Reserve Now
                </LVLpmThemeButtonOther>
              </Box>
            </Box>
          </Grid>
          {existingBookings.length > 0 && (
            <Grid
              container
              item
              md={6}
              xs={12}
              sx={{ display: "flex", justifyContent: "center", padding: "2%" }}
            >
              <Grid item xs={12}>
                <Typography variant="h4">Property Availbility</Typography>
              </Grid>
              <Grid item xs={12}>
                <CalendarView existingBookings={existingBookings} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
      <Footer bg={true} />
      <Dialog
        fullWidth
        maxWidth={"xl"}
        open={openAmenities}
        aria-labelledby="amenities-dialog-title"
        aria-describedby="amenities-dialog"
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            padding: "10px 0 10px 10px",
            backgroundColor: "#00B9D1",
            color: "#fff",
          }}
          id="amenities-dialog-title"
        >
          Property Amenities
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseAmenities}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container spacing={2}>
            {allActiveAmenities.map((value: string, index) => {
              let AmenitiesIcon =
                specificationsIcons[value as keyof typeof specificationsIcons];
              let label: string = dbKeyConverterToLabel(value);
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={index}
                  sx={{
                    display: "flex",
                    gap: 2,
                  }}
                >
                  <Typography>{<img src={`${AmenitiesIcon}`} />}</Typography>
                  <Typography sx={{ textTransform: "capitalize" }}>
                    {label}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
      </Dialog>
      <PaymentDialog
        open={openPaymentDialog}
        handleClose={handleClosePaymentDialog}
        nights={nights}
        taxes={taxes}
        stayPrice={stayPrice}
        cleaningFees={selectedPropertyDetails?.cleaning_fee}
        totalPrice={totalPrice}
        pricePerDay={pricePerDay}
        lvlpmFee={lvlpmFee}
        guests={reservationForm.values.guests}
      />
      <PropertyBookingDialog
        open={openPropertyBookingDialog}
        handleClose={handleClosePropertyBookingDialog}
        leads={propertyLeads}
      />
      <LoginRequiredDialog
        open={openLoginRequiredDialog}
        handleClose={handleCloseLoginRequiredDialog}
      />
      {isLoaded && (
        <UserVerificationDialog
          open={openUserVerificationDialog}
          handleClose={handleCloseUserVerificationDialog}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        open={openSnackbar}
        message={snackbarMessage}
        key={"topcenter"}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
};

export default ListingDetails;
