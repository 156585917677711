import { createSlice } from "@reduxjs/toolkit";

import {
  getAllProperties,
  getAllBookings,
  getAllAccounts,
  getAllPropertyStatus,
  validatePropertyAddress,
  getAllPropertyUsers,
  getAllPlatforms,
  getPropertyMonthlyIncome,
  getPropertyMonthlyExpense,
  getPropertyYearlyNetIncome,
} from "../../thunks/systemThunk";

interface SystemSlice {
  getAllPropertiesLoading: Boolean;
  getAllBookingsLoading: Boolean;
  getAllAccountsLoading: Boolean;
  getAllPropertyStatusLoading: Boolean;
  validatePropertyAddressLoading: Boolean;
  getAllPropertyUsersLoading: Boolean;
  getAllPlatformsLoading: Boolean;
  getPropertyMonthlyIncomeLoading: Boolean;
  getPropertyMonthlyExpenseLoading: Boolean;
  getPropertyYearlyNetIncomeLoading: Boolean;
  error: any;
  response: any;
  allProperties: any;
  allBookings: any;
  allAccounts: any;
  allPropertyStatus: any;
  allPropertyUsers: any;
  allPlatform: any;
  gross_monthly_income: any;
  gross_monthly_expense: any;
  gross_monthly_net_profit: any;
}

const initialState: SystemSlice = {
  getAllPropertiesLoading: false,
  getAllBookingsLoading: false,
  getAllAccountsLoading: false,
  getAllPropertyStatusLoading: false,
  validatePropertyAddressLoading: false,
  getAllPropertyUsersLoading: false,
  getAllPlatformsLoading: false,
  getPropertyMonthlyIncomeLoading: false,
  getPropertyMonthlyExpenseLoading: false,
  getPropertyYearlyNetIncomeLoading: false,
  error: null,
  response: null,

  allProperties: null,
  allBookings: null,
  allAccounts: null,
  allPropertyStatus: null,
  allPropertyUsers: null,
  allPlatform: null,
  gross_monthly_income: null,
  gross_monthly_expense: null,
  gross_monthly_net_profit: null,
};

const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    resetSystem: (state) => {
      state.getAllPropertiesLoading = false;
      state.getAllBookingsLoading = false;
      state.getAllAccountsLoading = false;
      state.getAllPropertyStatusLoading = false;
      state.validatePropertyAddressLoading = false;
      state.getAllPropertyUsersLoading = false;
      state.getAllPlatformsLoading = false;
      state.getPropertyMonthlyIncomeLoading = false;
      state.getPropertyMonthlyExpenseLoading = false;
      state.getPropertyYearlyNetIncomeLoading = false;
      state.error = null;
      state.response = null;
      state.allProperties = null;
      state.allBookings = null;
      state.allAccounts = null;
      state.allPropertyStatus = null;
      state.allPropertyUsers = null;
      state.allPlatform = null;
      state.gross_monthly_income = null;
      state.gross_monthly_expense = null;
      state.gross_monthly_net_profit = null;
    },
  },
  extraReducers(builder) {
    // Get All Properties
    builder.addCase(getAllProperties.pending, (state) => {
      state.getAllPropertiesLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(getAllProperties.fulfilled, (state, action) => {
      state.getAllPropertiesLoading = false;
      state.allProperties = action.payload.property_details;
    });
    builder.addCase(getAllProperties.rejected, (state, action) => {
      state.getAllPropertiesLoading = false;
      state.error = action.payload;
    });
    // Get All Bookings
    builder.addCase(getAllBookings.pending, (state) => {
      state.getAllBookingsLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(getAllBookings.fulfilled, (state, action) => {
      state.getAllBookingsLoading = false;
      state.allProperties = action.payload.property_details;
    });
    builder.addCase(getAllBookings.rejected, (state, action) => {
      state.getAllBookingsLoading = false;
      state.error = action.payload;
    });
    // Get All Accounts
    builder.addCase(getAllAccounts.pending, (state) => {
      state.getAllAccountsLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(getAllAccounts.fulfilled, (state, action) => {
      state.getAllAccountsLoading = false;
      state.allAccounts = action.payload.accounts_details;
    });
    builder.addCase(getAllAccounts.rejected, (state, action) => {
      state.getAllAccountsLoading = false;
      state.error = action.payload;
    });
    // Get All Property Status
    builder.addCase(getAllPropertyStatus.pending, (state) => {
      state.getAllPropertyStatusLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(getAllPropertyStatus.fulfilled, (state, action) => {
      state.getAllPropertyStatusLoading = false;
      state.allPropertyStatus = action.payload.all_property_status;
    });
    builder.addCase(getAllPropertyStatus.rejected, (state, action) => {
      state.getAllPropertyStatusLoading = false;
      state.error = action.payload;
    });
    // Validate Property Address
    builder.addCase(validatePropertyAddress.pending, (state) => {
      state.validatePropertyAddressLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(validatePropertyAddress.fulfilled, (state, action) => {
      state.validatePropertyAddressLoading = false;
      state.response = action.payload.all_property_status;
    });
    builder.addCase(validatePropertyAddress.rejected, (state, action) => {
      state.validatePropertyAddressLoading = false;
      state.error = action.payload;
    });
    // Get All Property Users
    builder.addCase(getAllPropertyUsers.pending, (state) => {
      state.getAllPropertyUsersLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(getAllPropertyUsers.fulfilled, (state, action) => {
      state.getAllPropertyUsersLoading = false;
      state.allPropertyUsers = action.payload.all_property_users;
    });
    builder.addCase(getAllPropertyUsers.rejected, (state, action) => {
      state.getAllPropertyUsersLoading = false;
      state.error = action.payload;
    });
    // Get All Platforms
    builder.addCase(getAllPlatforms.pending, (state) => {
      state.getAllPlatformsLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(getAllPlatforms.fulfilled, (state, action) => {
      state.getAllPlatformsLoading = false;
      state.allPlatform = action.payload.all_platform;
    });
    builder.addCase(getAllPlatforms.rejected, (state, action) => {
      state.getAllPlatformsLoading = false;
      state.error = action.payload;
    });
    // Get Property Monthly Income
    builder.addCase(getPropertyMonthlyIncome.pending, (state) => {
      state.getPropertyMonthlyIncomeLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(getPropertyMonthlyIncome.fulfilled, (state, action) => {
      state.getPropertyMonthlyIncomeLoading = false;
      state.gross_monthly_income = action.payload.gross_monthly_income;
    });
    builder.addCase(getPropertyMonthlyIncome.rejected, (state, action) => {
      state.getPropertyMonthlyIncomeLoading = false;
      state.error = action.payload;
    });
    // Get Property Monthly Expense
    builder.addCase(getPropertyMonthlyExpense.pending, (state) => {
      state.getPropertyMonthlyExpenseLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(getPropertyMonthlyExpense.fulfilled, (state, action) => {
      state.getPropertyMonthlyExpenseLoading = false;
      state.gross_monthly_expense = action.payload.gross_monthly_expense;
    });
    builder.addCase(getPropertyMonthlyExpense.rejected, (state, action) => {
      state.getPropertyMonthlyExpenseLoading = false;
      state.error = action.payload;
    });
    // Get Property Yearly Net Income
    builder.addCase(getPropertyYearlyNetIncome.pending, (state) => {
      state.getPropertyYearlyNetIncomeLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(getPropertyYearlyNetIncome.fulfilled, (state, action) => {
      state.getPropertyYearlyNetIncomeLoading = false;
      state.gross_monthly_net_profit = action.payload.gross_monthly_net_profit;
    });
    builder.addCase(getPropertyYearlyNetIncome.rejected, (state, action) => {
      state.getPropertyYearlyNetIncomeLoading = false;
      state.error = action.payload;
    });
  },
});

export const { resetSystem } = systemSlice.actions;

export const systemReducer = systemSlice.reducer;
