import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Divider,
  Input,
  Container,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

import logo from "./../../../assets/logo.jpg";
import { Link } from "react-router-dom";
import { LVLpmThemeButtonOther } from "../../../utilities/constants";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { Formik, useFormik } from "formik";
import { addNewSubscription } from "../../../store/thunks/subscribeThunk";

interface FooterProps {
  bg: boolean;
}

const Footer = (props: FooterProps) => {
  const { bg } = props;
  const subscribeNewsletter = () => {};
  const [email, setEmail] = useState("");
  const dispatch = useAppDispatch();

  const initialValues = {
    email: "",
  };
  const handleSubscription = () => {
    dispatch(addNewSubscription({ email: formik.values.email })).then(
      (res: any) => {
        if (res.payload.isSubscribed) {
          alert("Subscribed successfully.");
          location.reload();
        } else if (res.error.message === "Rejected") {
          alert("Already subscribed, please use different email.");
        }
      }
    );
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubscription,
  });
  return (
    <Box
      sx={{
        height: "300px",
        position: "relative",
      }}
    >
      {bg && (
        <Box
          sx={{
            height: "400px",
            width: "100%",
            zIndex: "-1",
            backgroundImage: "linear-gradient(white, #D5EEF5)",
            position: "absolute",
            top: "-400px",
            left: "0",
          }}
        ></Box>
      )}
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 25px",
        }}
      >
        <Grid item md={2.5} sm={12} xs={12} sx={{ margin: "25px 0 40px 0" }}>
          <img style={{ maxHeight: "60px" }} src={logo} alt={"LVLPM"} />
          <Box sx={{ width: { sm: "50%", md: "100%" } }}>
            <Typography variant="caption" sx={{ color: "#9c9c9c" }}>
              A SaaS enabled marketplace delivering passive income streams to
              property owners by creating memorable hotel-like quality stays for
              guests.
            </Typography>
          </Box>
        </Grid>
        <Grid item md={6} sm={12} xs={12} sx={{ margin: "25px 0 40px 0" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Box>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", marginBottom: "10px" }}
              >
                Support
              </Typography>
              {/* <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                Help Center
              </Typography> */}
              {/* <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                Owner Help
              </Typography> */}
              <Link
                to="/cancellation-policy"
                style={{ color: "#000", textDecoration: "none" }}
              >
                <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                  Cancellation Policy
                </Typography>
              </Link>
              <Link
                to="/terms-and-conditions"
                style={{ color: "#000", textDecoration: "none" }}
              >
                <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                  Terms And Conditions
                </Typography>
              </Link>
              <Link
                to="/privacy-policy"
                style={{ color: "#000", textDecoration: "none" }}
              >
                <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                  Privacy Policy
                </Typography>
              </Link>
              <Link
                to="/contact"
                style={{ color: "#000", textDecoration: "none" }}
              >
                <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                  Contact
                </Typography>
              </Link>
            </Box>
            <Box>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", marginBottom: "10px" }}
              >
                Company
              </Typography>
              <Link
                to="/about-us"
                style={{ color: "#000", textDecoration: "none" }}
              >
                <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                  About Us
                </Typography>
              </Link>
              {/* <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                Careers
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                Investors
              </Typography> */}
              <Link
                to="/blog"
                style={{ color: "#000", textDecoration: "none" }}
              >
                <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                  Blog
                </Typography>
              </Link>
            </Box>
            <Box>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", marginBottom: "10px" }}
              >
                Product
              </Typography>
              <Link
                to="/company-management"
                style={{ color: "#000", textDecoration: "none" }}
              >
                <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                  Management
                </Typography>
              </Link>
              <Link
                to="/real-estate"
                style={{ color: "#000", textDecoration: "none" }}
              >
                <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                  Real Estate
                </Typography>
              </Link>
              <Link
                to="/listings"
                style={{ color: "#000", textDecoration: "none" }}
              >
                <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                  Search Listings
                </Typography>
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid item md={3} sm={12} xs={12} sx={{ margin: "25px 0 40px 0" }}>
          <Box
            sx={{
              margin: "0 0 50px 0",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Subscribe to our newsletter
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#9c9c9c", margin: "5px 0" }}
              >
                For announcements and exclusive deals
              </Typography>
              <TextField
                placeholder="Email address"
                id="email"
                name="email"
                onChange={formik.handleChange}
                sx={{ width: "100%" }}
              />
              <LVLpmThemeButtonOther variant="contained" type="submit">
                Subscribe
              </LVLpmThemeButtonOther>
            </form>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ borderTop: "1px solid #e6e6e6", padding: "0 30px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "15px 0px",
              }}
            >
              <Typography variant="body2" sx={{ color: "#9c9c9c" }}>
                &copy; LVLpm 2024
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FacebookIcon sx={{ color: "#9c9c9c", marginRight: "20px" }} />
                <TwitterIcon sx={{ color: "#9c9c9c", marginRight: "20px" }} />
                <InstagramIcon sx={{ color: "#9c9c9c" }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Footer;
