import React from "react";
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Paper,
  Grid,
  IconButton,
  Button,
  Icon,
} from "@mui/material";

import OwnerIcon from "../../../assets/icons/icon1 1.png";
import RenterIcon from "../../../assets/icons/icon1 1-3.png";
import SecurePaymentIcon from "../../../assets/icons/icon1 1-2.png";
import LessFeeIcon from "../../../assets/icons/icon1 1-1.png";
import { useAppDispatch } from "../../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import { setCurrentDestination } from "../../../store";

interface DisplayImageProps {
  image?: any;
  title: string;
  description: string;
  vacationContent: Boolean;
  iconContent: Boolean;
  iconType?: string;
}

const DisplayImage = (prop: DisplayImageProps) => {
  const { image, title, description, vacationContent, iconContent, iconType } =
    prop;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <>
      {vacationContent && (
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{ marginBottom: "10px", padding: "5px" }}
          onClick={() => {
            if (image?.state_name !== "other") {
              dispatch(
                setCurrentDestination({
                  checkIn: "2024-11-11T19:57:43.076Z",
                  checkOut: "2024-11-14T19:57:43.076Z",
                  destination: image?.state_name,
                  guests: 2,
                  value: {
                    condition: "state",
                    label:
                      image?.state_name.replace(
                        /(^\w{1})|(\s+\w{1})/g,
                        (letter: string) => letter?.toUpperCase()
                      ) +
                      ", " +
                      image?.iso2?.toUpperCase(),
                    state_code: image?.state_code,
                    value: {
                      state_code: image?.state_code,
                      state_id: image?.state_id,
                      state_name: image?.state_name,
                    },
                  },
                })
              );
            } else {
              dispatch(
                setCurrentDestination({
                  checkIn: "2024-11-11T19:57:43.076Z",
                  checkOut: "2024-11-14T19:57:43.076Z",
                  destination: "Philippines",
                  guests: 2,
                  value: {
                    condition: "country",
                    value: {
                      country: image?.country_name,
                      country_code: image?.iso2,
                      country_id: image?.country_id,
                    },
                    country_code: image?.iso2,
                    label: image?.country_name,
                  },
                })
              );
            }
            navigate("/listings");
          }}
        >
          <Box sx={{ height: "350px", marginBottom: "10px" }}>
            <img
              src={
                image?.property_images_url
                  ? image?.property_images_url.split(",")[
                      Math.floor(
                        Math.random() *
                          image?.property_images_url.split(",").length
                      )
                    ]
                  : null
              }
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
          <Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                color: "#9c9c9c",
              }}
            >
              {description}
            </Typography>
          </Box>
        </Grid>
      )}
      {iconContent && (
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            textAlign: "center",
            width: "100%",
          }}
        >
          <Card sx={{ height: "350px", padding: { md: "15px", xs: "25px" } }}>
            <Icon
              sx={{
                width: 100,
                height: 100,
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "auto",
                marginBottom: "15px",
              }}
              aria-label="search"
            >
              {iconType === "owner" && (
                <img src={OwnerIcon} style={{ width: 100, height: 100 }} />
              )}
              {iconType === "renter" && (
                <img src={RenterIcon} style={{ width: 100, height: 100 }} />
              )}
              {iconType === "payment" && (
                <img
                  src={SecurePaymentIcon}
                  style={{ width: 100, height: 100 }}
                />
              )}
              {iconType === "fee" && (
                <img src={LessFeeIcon} style={{ width: 100, height: 100 }} />
              )}
            </Icon>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                paddingBottom: iconContent ? "15px" : "",
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                color: "#9c9c9c",
                fontSize: "16px",
              }}
            >
              {description}
            </Typography>
          </Card>
        </Grid>
      )}
    </>
  );
};

export default DisplayImage;
