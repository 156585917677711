import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";

const data = [
  { value: 88.9, label: "Occupancy" },
  { value: 6, label: "Cancellation" },
];

const RoundChart = (props) => {
  const { data, innerRadius } = props;
  return <PieChart series={[{ data, innerRadius }]} height={200} />;
};
export default RoundChart;
