import { SyntheticEvent, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { LVLpmThemeButton } from "../../../../utilities/constants";
import Spinner from "../../../../components/reusableComponents/spinner/spinner";
import { displayConsoleError } from "../../../../utilities/helperFunctions";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { platform } from "os";
import { updateBookingFee } from "../../../../store/thunks/bookingsThunk";

interface UpdateBookingFeeDialogProps {
  open: boolean;
  handleCloseCancel: (event: any) => void;
  selectedBookingDetails: any;
}

const UpdateBookingFeeDialog = (props: UpdateBookingFeeDialogProps) => {
  const { open, handleCloseCancel, selectedBookingDetails } = props;

  const { allPlatform } = useAppSelector((state: any) => state.system);
  const { updateBookingFeeLoading } = useAppSelector(
    (state: any) => state.bookings
  );

  const dispatch = useAppDispatch();
  const handleCloseUpdateBookingFeeDialog = (event: any) => {
    handleCloseCancel(event);
  };

  const [updateBookingFeeError, setUpdateBookingFeeError] = useState(false);
  const [updateBookingFeeErrorMessage, setUpdateBookingFeeErrorMessage] =
    useState("");
  const [selectedUpdatebookingtype, setSelectedUpdatebookingtype] =
    useState<any>();
  const [selectedPlatform, setSelectedPlatform] = useState<any>();
  const [updateBookingFeeAmmount, setUpdateBookingFeeAmmount] = useState<any>();

  const cancelUpdateBookingFeeDialog = (event: any) => {
    setSelectedUpdatebookingtype(null);
    setSelectedPlatform(null);
    setUpdateBookingFeeAmmount(null);
    handleCloseCancel(event);
  };

  const [enableUpdateBookingFee, setEnablUpdateBookingFee] = useState(true);

  const checkEnableUpdateBookingFee = () => {
    if (selectedUpdatebookingtype) {
      if (selectedUpdatebookingtype?.value === "payment") {
        if (selectedPlatform) {
          if (Number(updateBookingFeeAmmount) > 0) {
            setEnablUpdateBookingFee(false);
          } else {
            setEnablUpdateBookingFee(true);
          }
        } else {
          setEnablUpdateBookingFee(true);
        }
      } else {
        if (Number(updateBookingFeeAmmount) > 0) {
          setEnablUpdateBookingFee(false);
        } else {
          setEnablUpdateBookingFee(true);
        }
      }
    } else {
      setEnablUpdateBookingFee(true);
    }
  };

  useEffect(() => {
    checkEnableUpdateBookingFee();
  }, [selectedUpdatebookingtype, selectedPlatform, updateBookingFeeAmmount]);

  const submitUpdateBookingFee = () => {
    const updateBookingFeePayload = {
      bookingDetails: selectedBookingDetails,
      updateBookingFeeType: selectedUpdatebookingtype,
      platform: selectedPlatform,
      amount: updateBookingFeeAmmount,
    };

    dispatch(
      updateBookingFee({ update_booking_fee_payload: updateBookingFeePayload })
    )
      .then((updateBookingFeeResult: any) => {
        if (updateBookingFeeResult?.payload?.status) {
          cancelUpdateBookingFeeDialog(
            updateBookingFeeResult?.payload?.message
          );
        } else {
          setUpdateBookingFeeError(true);
          setUpdateBookingFeeErrorMessage(
            "Unable to update booking fee, Please try again later or contact support"
          );
        }
      })
      .catch((updateBookingFeeError: any) => {
        displayConsoleError("leadsInfoCheckingError", updateBookingFeeError);
        setUpdateBookingFeeError(true);
        setUpdateBookingFeeErrorMessage(
          "Unable to update booking fee, Please try again later or contact support"
        );
      });
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true} scroll="paper">
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <Spinner loading={updateBookingFeeLoading} />
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Update Booking Fee
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleCloseUpdateBookingFeeDialog("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <Grid sx={{ height: "300px" }}>
          <Grid container>
            <Grid item xs={12} md={6} lg={6} sx={{ padding: "1%" }}>
              <TextField
                id="property_name"
                label="Property Name"
                name="property_name"
                value={selectedBookingDetails?.property_name}
                sx={{
                  width: "100%",
                  marginBottom: "5px",
                }}
                disabled
              />
            </Grid>
            <Grid container sx={{ padding: "1%" }} spacing={2}>
              <Grid item md={4} xs={12}>
                <Box>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", marginBottom: "5px" }}
                  >
                    Booking Date
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      name="checkIn"
                      value={dayjs(
                        new Date(selectedBookingDetails?.booking_create_date)
                      ).utc()}
                      disabled
                      views={["year", "month", "day"]}
                      sx={{ width: "100%" }}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                <Box>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", marginBottom: "5px" }}
                  >
                    Check In
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      name="checkIn"
                      value={dayjs(
                        new Date(selectedBookingDetails?.check_in)
                      ).utc()}
                      disabled
                      views={["year", "month", "day"]}
                      sx={{ width: "100%" }}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", marginBottom: "5px" }}
                >
                  Check Out
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="checkOut"
                    value={dayjs(
                      new Date(selectedBookingDetails?.check_out)
                    ).utc()}
                    disabled
                    views={["year", "month", "day"]}
                    sx={{ width: "100%" }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid container sx={{ padding: "1%" }} spacing={2}>
              <Grid item md={4} xs={12}>
                <Autocomplete
                  options={[
                    {
                      value: "refund",
                      label: "Refund",
                    },
                    { value: "payment", label: "Payment" },
                  ]}
                  onChange={(_, value: any) => {
                    setSelectedUpdatebookingtype(value);
                    setSelectedPlatform(null);
                  }}
                  slotProps={{}}
                  ListboxProps={{}}
                  value={selectedUpdatebookingtype}
                  sx={{ width: "100%" }}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ textTransform: "capitalize" }}
                      label={"Update Type"}
                      id="update_type"
                      name="update_type"
                    />
                  )}
                />
              </Grid>
              {selectedUpdatebookingtype?.value === "payment" && (
                <Grid item md={4} xs={12}>
                  <Autocomplete
                    options={allPlatform ? allPlatform : []}
                    onChange={(_, value: any) => setSelectedPlatform(value)}
                    getOptionLabel={(option) => option.booking_location_label}
                    value={selectedPlatform}
                    sx={{ width: "100%" }}
                    filterSelectedOptions={true}
                    isOptionEqualToValue={(option, value) =>
                      option.booking_location_id === value.booking_location_id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ textTransform: "capitalize" }}
                        label={"Platform"}
                        id="platform"
                        name="platform"
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={4} sx={{ padding: "1%" }}>
                <TextField
                  id="total_amount"
                  label="Total Amount"
                  name="total_amount"
                  value={updateBookingFeeAmmount}
                  type="number"
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  onChange={(event) =>
                    setUpdateBookingFeeAmmount(event.target.value)
                  }
                  sx={{
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item md={4} xs={12}></Grid>
            </Grid>
            {updateBookingFeeError && (
              <Grid
                container
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography sx={{ color: "red" }}>
                  {updateBookingFeeErrorMessage}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => cancelUpdateBookingFeeDialog("")}
        >
          Cancel
        </LVLpmThemeButton>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => submitUpdateBookingFee()}
          disabled={enableUpdateBookingFee}
        >
          Submit
        </LVLpmThemeButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateBookingFeeDialog;
