import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { useAppDispatch } from "../../../../hooks/hooks";
import { LVLpmThemeButton } from "../../../../utilities/constants";
import { changePw, checkPw } from "../../../../store/thunks/userThunk";

interface ChangePwModalProps {
  open: boolean;
  handleClose: any;
  pw: string;
  userInfo: any;
}

const ChangePwModal = (props: ChangePwModalProps) => {
  const { open, handleClose, pw, userInfo } = props;
  const dispatch = useAppDispatch();
  const [isMatch, setIsMatch] = useState(false);
  const [isNotMatch, setIsNotMatch] = useState(false);
  const [changeOpen, setChangeOpen] = useState(false);
  const changeFormOpen = () => setChangeOpen(true);
  const changeFormClose = () => setChangeOpen(false);

  useEffect(() => {
    formik.setFieldValue("currentPw", "");
  }, [open]);

  const initialValues = {
    currentPw: "",
  };

  const onSubmit = (values: any) => {
    dispatch(checkPw({ userPw: pw, currentPw: values.currentPw })).then(
      (res) => {
        if (res.type.includes("rejected")) {
          setIsNotMatch(true);
          setIsMatch(false);
        } else {
          setIsMatch(res.payload.checkPwResult);
          setIsNotMatch(false);
        }
      }
    );
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  const validateNewPw = Yup.object({
    newPw: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
    checkNewPw: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
  });

  const newInitialValues = {
    newPw: "",
    checkNewPw: "",
  };

  const newOnSubmit = (values: any) => {
    const newPw = values.newPw;
    const checkNewPw = values.checkNewPw;
    if (newPw === checkNewPw) {
      dispatch(changePw({ user_id: userInfo.user_id, newPw })).then((res) => {
        alert("Password Changed");
        location.reload();
      });
    } else {
      alert("Passwords do not match.");
    }
  };

  const newFormik = useFormik({
    validationSchema: validateNewPw,
    initialValues: newInitialValues,
    onSubmit: newOnSubmit,
  });

  const matchesX = useMediaQuery("(min-width:1100px)");
  const matchesY = useMediaQuery("(min-height:1200px)");
  return (
    <>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        scroll="paper"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container style={{ backgroundColor: "#00B9D1" }}>
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            style={{ color: "white", fontWeight: "bold" }}
          >
            Check Password
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              handleClose("");
              setIsMatch(false);
              setIsNotMatch(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#fff",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction="row"
              spacing={2}
              sx={{ padding: "30px 0 0 30px" }}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  type="password"
                  id="currentPw"
                  label="Current Password"
                  name="currentPw"
                  value={formik.values.currentPw}
                  onChange={formik.handleChange}
                  sx={{ width: "100%" }}
                />
                {isMatch ? (
                  <span style={{ color: "#8AC53E" }}>Password Matched!</span>
                ) : (
                  ""
                )}
                {isNotMatch ? (
                  <span style={{ color: "red" }}>Password Not Matched...</span>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Box sx={{ padding: " 30px" }}>
              {isMatch ? (
                <LVLpmThemeButton
                  onClick={() => {
                    changeFormOpen();
                    handleClose();
                  }}
                  variant="contained"
                  color="success"
                >
                  Change Password
                </LVLpmThemeButton>
              ) : (
                <LVLpmThemeButton
                  type="submit"
                  variant="contained"
                  color="success"
                  disabled={!formik.dirty}
                >
                  Check Password
                </LVLpmThemeButton>
              )}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      {changeOpen && (
        <Dialog
          open={changeOpen}
          maxWidth="md"
          fullWidth
          scroll="paper"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid container style={{ backgroundColor: "#00B9D1" }}>
            <DialogTitle
              sx={{ m: 0, p: 2 }}
              style={{ color: "white", fontWeight: "bold" }}
            >
              Change Password
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => {
                handleClose("");
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#fff",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <DialogContent>
            <form onSubmit={newFormik.handleSubmit}>
              <Grid
                container
                direction="row"
                spacing={2}
                sx={{ padding: "30px 30px 0 30px" }}
              >
                <Grid item xs={12} md={6}>
                  <TextField
                    type="password"
                    id="newPw"
                    label="New Password"
                    name="newPw"
                    value={newFormik.values.newPw}
                    onChange={newFormik.handleChange}
                    sx={{ width: "100%" }}
                    error={
                      newFormik.touched.newPw && Boolean(newFormik.errors.newPw)
                    }
                    helperText={newFormik.errors.newPw}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="password"
                    id="checkNewPw"
                    label="Check New Password"
                    name="checkNewPw"
                    value={newFormik.values.checkNewPw}
                    onChange={newFormik.handleChange}
                    sx={{ width: "100%" }}
                    error={
                      newFormik.touched.checkNewPw &&
                      Boolean(newFormik.errors.checkNewPw)
                    }
                    helperText={newFormik.errors.checkNewPw}
                  />
                </Grid>
              </Grid>
              <Box sx={{ padding: " 30px" }}>
                <LVLpmThemeButton
                  type="submit"
                  variant="contained"
                  color="success"
                >
                  Confirm Change Password
                </LVLpmThemeButton>
              </Box>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default ChangePwModal;
