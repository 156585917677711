import { createSlice } from "@reduxjs/toolkit";
import {
  loginUser,
  registerUser,
  verifyEmail,
  resetPassword,
  forgotPassowrd,
  forgotPasswordVerification,
  logoutUser,
  getSupportUsers,
} from "../../thunks/authenticationThunk";

interface AuthenticationState {
  loginLoading: Boolean;
  logoutLoading: Boolean;
  registerLoading: Boolean;
  verifyEmailLoading: Boolean;
  forgotPassowrdLoading: Boolean;
  resetPasswordLoading: Boolean;
  forgotPasswordVerificationLoading: Boolean;
  getSupportUsersLoading: Boolean;
  userInfo: object | null;
  userToken: string | null;
  loginErrorMessage: any;
  error: any;
  response: any;
  success: Boolean;
  loggedIn: Boolean;
  supportUsers: any;
  previousLocation: string;
}

const initialState: AuthenticationState = {
  loginLoading: false,
  logoutLoading: false,
  registerLoading: false,
  verifyEmailLoading: false,
  forgotPassowrdLoading: false,
  resetPasswordLoading: false,
  forgotPasswordVerificationLoading: false,
  getSupportUsersLoading: false,
  userInfo: {},
  loggedIn: false,
  userToken: null,
  loginErrorMessage: null,
  error: null,
  response: null,
  supportUsers: null,
  success: false,
  previousLocation: "",
};

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.clear();
      state.loginLoading = false;
      state.logoutLoading = false;
      state.registerLoading = false;
      state.verifyEmailLoading = false;
      state.forgotPassowrdLoading = false;
      state.resetPasswordLoading = false;
      state.forgotPasswordVerificationLoading = false;
      state.getSupportUsersLoading = false;
      state.userInfo = null;
      state.userToken = null;
      state.loginErrorMessage = null;
      state.error = null;
      state.loggedIn = false;
      state.success = false;
      state.previousLocation = "";
      state.response = null;
      state.supportUsers = null;
    },
    reset: (state) => {
      state.error = null;
      state.success = false;
      state.response = null;
      state.supportUsers = null;
    },
    setPreviousLocation: (state, action) => {
      state.previousLocation = action.payload;
    },
  },
  extraReducers(builder) {
    // Login User
    builder.addCase(loginUser.pending, (state) => {
      state.loginLoading = true;
      state.loggedIn = false;
      state.loginErrorMessage = null;
      state.response = null;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loginLoading = false;
      state.loggedIn = true;
      state.userToken = action.payload.accessToken;
      state.userInfo = action.payload.userInfo;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loginLoading = false;
      state.loggedIn = false;
      state.loginErrorMessage = action.payload;
    });
    // Logout User
    builder.addCase(logoutUser.pending, (state) => {
      state.logoutLoading = true;
      state.loggedIn = false;
      state.error = null;
      state.response = null;
    });
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      localStorage.clear();
      state.logoutLoading = false;
      state.loggedIn = false;
      state.userToken = null;
      state.userInfo = null;
      state.previousLocation = "";
    });
    builder.addCase(logoutUser.rejected, (state, action) => {
      state.logoutLoading = false;
      state.loggedIn = false;
      state.error = action.payload;
    });
    // Register User
    builder.addCase(registerUser.pending, (state) => {
      state.registerLoading = true;
      state.success = false;
      state.response = null;
      state.error = null;
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.registerLoading = false;
      state.success = true;
      state.response = action.payload;
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.registerLoading = false;
      state.error = action.payload;
    });
    // Verify Email
    builder.addCase(verifyEmail.pending, (state) => {
      state.verifyEmailLoading = true;
      state.success = false;
      state.response = null;
      state.error = null;
    });
    builder.addCase(verifyEmail.fulfilled, (state, action) => {
      state.verifyEmailLoading = false;
      state.success = true;
      state.response = action.payload;
    });
    builder.addCase(verifyEmail.rejected, (state, action) => {
      state.verifyEmailLoading = false;
      state.error = action.payload;
    });
    // Forgot Password
    builder.addCase(forgotPassowrd.pending, (state) => {
      state.forgotPassowrdLoading = true;
      state.success = false;
      state.response = null;
      state.error = null;
    });
    builder.addCase(forgotPassowrd.fulfilled, (state, action) => {
      state.forgotPassowrdLoading = false;
      state.success = true;
      state.response = action.payload;
    });
    builder.addCase(forgotPassowrd.rejected, (state, action) => {
      state.forgotPassowrdLoading = false;
      state.error = action.payload;
    });
    // Reset Password
    builder.addCase(resetPassword.pending, (state) => {
      state.resetPasswordLoading = true;
      state.success = false;
      state.response = null;
      state.error = null;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.resetPasswordLoading = false;
      state.success = true;
      state.response = action.payload;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.resetPasswordLoading = false;
      state.error = action.payload;
    });
    // Forgot Password Verification
    builder.addCase(forgotPasswordVerification.pending, (state) => {
      state.forgotPasswordVerificationLoading = true;
      state.success = false;
      state.response = null;
      state.error = null;
    });
    builder.addCase(forgotPasswordVerification.fulfilled, (state, action) => {
      state.forgotPasswordVerificationLoading = false;
      state.success = true;
      state.response = action.payload;
    });
    builder.addCase(forgotPasswordVerification.rejected, (state, action) => {
      state.forgotPasswordVerificationLoading = false;
      state.error = action.payload;
    });
    // Get Support Users
    builder.addCase(getSupportUsers.pending, (state) => {
      state.getSupportUsersLoading = true;
      state.supportUsers = null;
      state.error = null;
    });
    builder.addCase(getSupportUsers.fulfilled, (state, action) => {
      state.getSupportUsersLoading = false;
      state.supportUsers = action.payload.support_ussers;
    });
    builder.addCase(getSupportUsers.rejected, (state, action) => {
      state.getSupportUsersLoading = false;
      state.error = action.payload;
    });
  },
});

export const { logout, reset, setPreviousLocation } =
  authenticationSlice.actions;

export const authenticationReducer = authenticationSlice.reducer;
