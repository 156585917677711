import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { LVLpmThemeButton } from "../../../utilities/constants";
import {
  getPropertyImages,
  updatePropertyImages,
} from "../../../store/thunks/propertyThunk";
import { displayConsoleError } from "../../../utilities/helperFunctions";
import Spinner from "../../reusableComponents/spinner/spinner";

interface UpdatePropertyImagesDialogProps {
  open: boolean;
  handleCloseCancel: (event: any) => void;
  selectedPropertyDetails: any;
}

const UpdatePropertyImagesDialog = (props: UpdatePropertyImagesDialogProps) => {
  const { open, handleCloseCancel, selectedPropertyDetails } = props;

  const { updatePropertyImagesLoading } = useAppSelector(
    (state: any) => state.property
  );

  const [enableUpdatePropertyImages, setEnableUpdatePropertyImages] =
    useState(true);

  const [updatePropertyImagesError, setUpdatePropertyImagesError] =
    useState(false);
  const [
    updatePropertyImagesErrorMessage,
    setUpdatePropertyImagesErrorMessage,
  ] = useState("");

  const [currentPropertyImages, setCurrentPropertyImages] = useState<any[]>([]);
  const [removePropertyImages, setRemovePropertyImages] = useState<any[]>([]);
  const [newPropertyImages, setNewPropertyImages] = useState<any[]>([]);
  const [newPropertyImagesFiles, setNewPropertyFiles] = useState<any>([]);

  const dispatch = useAppDispatch();

  const handleCloseUpdatePropertyImagesDialog = (event: any) => {
    setUpdatePropertyImagesError(false);
    setUpdatePropertyImagesErrorMessage("");
    setCurrentPropertyImages([]);
    setRemovePropertyImages([]);
    setNewPropertyImages([]);
    setNewPropertyFiles(null);
    handleCloseCancel(event);
  };

  const submitUpdatePropertyImagesDialog = () => {
    setUpdatePropertyImagesError(false);
    setUpdatePropertyImagesErrorMessage("");

    dispatch(
      updatePropertyImages({
        images: newPropertyImagesFiles,
        property_id: selectedPropertyDetails?.property_id,
        removed_images: removePropertyImages,
      })
    )
      .then((imageUpdateResult: any) => {
        if (imageUpdateResult?.payload?.status) {
          handleCloseUpdatePropertyImagesDialog(
            imageUpdateResult?.payload?.message
          );
        } else {
          setUpdatePropertyImagesError(true);
          setUpdatePropertyImagesErrorMessage(
            "Unable to update property Images. Images load is too large. Please reduce the number of images."
          );
        }
      })
      .catch((imageUpdateError: any) => {
        displayConsoleError("addOwnerProperty", imageUpdateError);
        setUpdatePropertyImagesError(true);
        setUpdatePropertyImagesErrorMessage(
          "Unable to update property Images. Images load is too large. Please reduce the number of images."
        );
      });
  };

  useEffect(() => {
    dispatch(
      getPropertyImages({
        property_id: selectedPropertyDetails?.property_id,
      })
    ).then((getPropertyImagesResults: any) => {
      setCurrentPropertyImages(
        getPropertyImagesResults?.payload?.property_images
      );
    });
  }, [selectedPropertyDetails]);

  const checkEnableUpdate = () => {
    if (removePropertyImages?.length > 0 || newPropertyImages?.length > 0) {
      setEnableUpdatePropertyImages(false);
    } else {
      setEnableUpdatePropertyImages(true);
    }
  };

  useEffect(() => {
    checkEnableUpdate();
  }, [removePropertyImages, newPropertyImages]);

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true}>
      <Spinner loading={updatePropertyImagesLoading} />
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Update Property Images
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleCloseUpdatePropertyImagesDialog("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <Grid container>
          <Grid item xs={6} sx={{ padding: "1%" }}>
            <TextField
              id="property_name"
              label="Property Name"
              name="property_name"
              value={selectedPropertyDetails?.property_name}
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "1%" }}>
          {currentPropertyImages?.length > 0 && (
            <Grid container>
              <Grid container item xs={6} sx={{ padding: "1%" }}>
                <Autocomplete
                  multiple
                  options={currentPropertyImages}
                  disabled
                  getOptionLabel={(option) => option.image_name}
                  value={currentPropertyImages}
                  sx={{ width: "100%" }}
                  filterSelectedOptions={true}
                  isOptionEqualToValue={(option, value) =>
                    option.property_image_url_id === value.property_image_url_id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ textTransform: "capitalize" }}
                      label={"Current Property Images"}
                      id="current_property_images"
                      name="current_property_images"
                    />
                  )}
                />
              </Grid>
              <Grid container item xs={6} sx={{ padding: "1%" }}>
                <Autocomplete
                  multiple
                  options={currentPropertyImages}
                  onChange={(_, value: any) => setRemovePropertyImages(value)}
                  getOptionLabel={(option) => option.image_name}
                  value={removePropertyImages}
                  sx={{ width: "100%" }}
                  filterSelectedOptions={true}
                  isOptionEqualToValue={(option, value) =>
                    option.property_image_url_id === value.property_image_url_id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ textTransform: "capitalize" }}
                      label={"Images To remove"}
                      id="images_to_remove"
                      name="images_to_remove"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{ padding: "1%" }}>
                <Box
                  sx={{
                    display: "flex",
                    overflowX: "auto",
                    maxWidth: "100%",
                  }}
                >
                  {currentPropertyImages?.map((image: any, index: number) => (
                    <img
                      key={index}
                      src={image?.image_url}
                      style={{
                        width: "150px",
                        height: "150px",
                        marginRight: "10px",
                      }}
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sx={{ display: "flex", flexDirection: "column", padding: "1%" }}
          >
            <label>New Property Images to Upload</label>
            <TextField
              id="new_property_images"
              name="new_property_images"
              inputProps={{
                accept: "image/*",
                multiple: true,
              }}
              type="file"
              onChange={(event: any) => {
                const filelist: any[] = Array.from(event.target.files);
                setNewPropertyImages(filelist);
                setNewPropertyFiles(event.target.files);
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ padding: "1%" }}>
            {newPropertyImages.length > 0 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    overflowX: "auto",
                    maxWidth: "100%",
                  }}
                >
                  {newPropertyImages.map((image: any, index: number) => (
                    <img
                      key={index}
                      src={URL.createObjectURL(image)}
                      style={{
                        width: "150px",
                        height: "150px",
                        marginRight: "10px",
                      }}
                    />
                  ))}
                </Box>
              </>
            )}
          </Grid>
        </Grid>
        {updatePropertyImagesError && (
          <Grid
            container
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography sx={{ color: "red" }}>
              {updatePropertyImagesErrorMessage}
            </Typography>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => handleCloseUpdatePropertyImagesDialog("")}
        >
          Cancel
        </LVLpmThemeButton>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => submitUpdatePropertyImagesDialog()}
          disabled={enableUpdatePropertyImages}
        >
          Submit
        </LVLpmThemeButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdatePropertyImagesDialog;
