import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";

import Logo from "../../assets/logo.jpg";
import TimeLine from "./TimeLine";
import rightDottedLine from "../../assets/rightDottedLine.svg";
import leftDottedLine from "../../assets/leftDottedLine.svg";
import OurValuesCard from "./OurValuesCard";
import TransparencyImg from "../../assets/Image 249.svg";
import AdaptabilityImg from "../../assets/Image 247.svg";
import IntegrityImg from "../../assets/Image 248.svg";
import MountainImg from "../../assets/Image 296.svg";
import PhoneImg from "../../assets/Group 36.jpg";
import AskImg from "../../assets/Container 594.svg";
import MsgImg from "../../assets/Button 281.svg";
import Footer from "../../components/commonComponents/footer/footer";
import FAQComponent from "./FAQComponent";
import { useEffect, useState } from "react";
import MobileTimeLine from "./MobileTimeLine";

const ourValues = [
  {
    id: 1,
    img: TransparencyImg,
    title: "Transparency",
    content:
      "Open communication fosters trust, building lasting relationships with clients and stakeholders.",
  },
  {
    id: 2,
    img: AdaptabilityImg,
    title: "Adaptability & Innovation",
    content:
      "Embrace innovation, adapt to change, and constantly enhance service quality.",
  },
  {
    id: 3,
    img: IntegrityImg,
    title: "Integrity",
    content:
      "Uphold unwavering integrity, earning trust through ethical, fair, and honest practices.",
  },
];

const contact = (
  <Typography>
    If you have any additional questions or need more information, our team is
    here to help! Reach out to us directly, and one of our dedicated consultants
    will be happy to provide personalized assistance and answer any inquiries
    you may have. <br /> Contact us at: <br /> Email: support@lvlpm.com";
  </Typography>
);

const faqLists = [
  {
    id: 1,
    title: "HOW DOES IT WORK?",
    content:
      "The first step is to connect with our Homeowner Consultant team. They will walk you through our services, evaluate whether your home aligns with our model, and discuss the potential benefits for you. From there, we’ll guide you through the onboarding process, including property setup, professional photography, and listing creation on our platform and across major booking sites. Once your property is live, our team handles everything—from guest communication to maintenance and housekeeping—providing a seamless, hands-off experience for you.",
  },
  {
    id: 2,
    title: "Why Choose LVLpm",
    content:
      "LVLpm offers a unique, comprehensive property management experience that combines top-tier hospitality services with innovative technology. We maximize your property’s potential through our multi-channel distribution strategy, advanced pricing algorithms, and expert marketing. Additionally, we partner with local vendors for on-the-ground support, ensuring your property is well-maintained and guest-ready. Our mission is to generate passive income for you while delivering exceptional experiences for guests, all with complete transparency through our owner dashboard.",
  },
  {
    id: 3,
    title: "Are there restrictions on how often I can use my home?",
    content:
      "No, there are no strict restrictions on how often you can use your home. We understand that it’s your property, and our flexible model allows you to reserve it for personal use as frequently as you like. Simply notify us of your plans, and we’ll make the necessary adjustments in our booking system to ensure availability aligns with your preferences.",
  },
  {
    id: 4,
    title: "DO I have to sign a long-term contract?",
    content:
      "We offer flexible contract options designed to meet different homeowner needs. While a longer-term contract can maximize earnings and occupancy, we provide the freedom to choose an arrangement that suits you best. Our goal is to build a long-lasting partnership, but we’re committed to offering flexibility and transparency every step of the way.",
  },
  {
    id: 5,
    title: "What services does LVLpm provide?",
    content:
      "LVLpm is a full-service property management solution. We handle everything, including listing creation, dynamic pricing, guest screening, 24/7 guest support, cleaning, and maintenance. Additionally, we offer unique upsell opportunities such as curated local experiences, concierge services, and in-app guidebooks to enhance the guest experience and maximize revenue. Our goal is to make property ownership as seamless and profitable as possible while delivering exceptional stays to guests.",
  },
  {
    id: 6,
    title: "HOW DOES LVLPM ENSURE THE SECURITY OF MY PROPERTY?",
    content:
      "At LVLpm, we take property security seriously. We implement a rigorous guest screening process and require identity verification for all bookings. Our smart home technology, including digital locks and monitoring systems, ensures that only authorized guests have access. Furthermore, we partner with trusted local vendors for regular property checks and maintenance, and our team is available 24/7 to respond to any emergencies, giving you peace of mind knowing your property is protected.",
  },
  {
    id: 7,
    title: "MORE QUESTIONS? CONTACT US",
    content: contact,
  },
];

const AboutUsPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const matchesX = useMediaQuery("(min-width:990px)");

  useEffect(() => {
    if (matchesX) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [matchesX]);

  return (
    <>
      <Box sx={{ textAlign: "center", padding: "50px 0" }}>
        <img src={Logo} style={{ width: "60%" }} />
      </Box>
      <Container
        maxWidth={false}
        sx={{
          padding: "50px 0",
          backgroundColor: "#F8F9FA",
          marginBottom: "50px",
          position: "relative",
        }}
      >
        <img
          src={rightDottedLine}
          style={{ position: "absolute", right: "-15px" }}
        />
        <img
          src={leftDottedLine}
          style={{ position: "absolute", bottom: "0", left: "-15px" }}
        />
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", textAlign: "center", marginBottom: "35px" }}
        >
          About Us
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {isMobile ? <MobileTimeLine /> : <TimeLine />}
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth={false}
        sx={{
          padding: "50px 0",
          backgroundColor: "#F8F9FA",
          marginBottom: "100px",
        }}
      >
        <Container maxWidth={"md"}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "25px",
            }}
          >
            Our Values
          </Typography>
          <Grid container spacing={3}>
            {ourValues.map((value) => (
              <OurValuesCard
                key={value.id}
                img={value.img}
                title={value.title}
                content={value.content}
              />
            ))}
          </Grid>
        </Container>
      </Container>
      <Container maxWidth={"md"} sx={{ marginBottom: "100px" }}>
        <Grid container spacing={4} sx={{}}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                marginBottom: "30px",
                paddingTop: "65px",
              }}
            >
              PROVIDING EXCEPTIONAL STAYS FOR BOTH THE OWNER AND RENTER
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={MountainImg} style={{ width: "100%", height: "100%" }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{}}>
              At LVLpm, we place the customer at the heart of everything we do.
              This commitment stems from our own experiences with property
              management services—where we identified gaps in service and sought
              to bridge them. Our goal is to deliver exceptional experiences for
              both owners and renters, creating value and delight on both sides.
              <br />
              <br />
              For renters, we provide thoughtfully curated stays with high-end
              amenities, personalized concierge services, and seamless booking
              processes to elevate every moment of their stay.
              <br />
              <br />
              For owners, we offer a streamlined management platform that
              maximizes passive income with minimal effort. Our combination of
              technology-driven operations, local partnerships, and transparent
              reporting ensures owners enjoy peace of mind and optimal returns
              from their investments. <br />
              At LVLpm, we don’t just manage properties—we cultivate experiences
              where everyone benefits, making each stay exceptional for guests
              and profitable for owners.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth={"lg"} sx={{ marginBottom: "100px" }}>
        <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
          <Grid item xs={12} md={4.6}>
            <Box
              sx={{ position: "relative", maxWidth: "330px", margin: "auto" }}
            >
              <Box sx={{ width: "330px", height: "590px" }}>
                <img src={PhoneImg} style={{ width: "100%" }} />
              </Box>
              <Box
                sx={{
                  width: "214px",
                  height: "65px",
                  position: "absolute",
                  left: "-25px",
                  top: "65px",
                }}
              >
                <img src={AskImg} style={{ width: "100%" }} />
              </Box>
              <Box
                sx={{
                  width: "72px",
                  height: "72px",
                  position: "absolute",
                  bottom: "140px",
                  right: "-15px",
                }}
              >
                <img src={MsgImg} style={{ width: "100%" }} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={7.4} sx={{ paddingLeft: "50px" }}>
            <Typography
              variant="body2"
              sx={{ color: "#00BDD6", marginBottom: "5px" }}
            >
              FAQ's
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", marginBottom: "25px" }}
            >
              MOST COMMON QUESTIONS ASKED
            </Typography>
            {faqLists.map((faq) => (
              <FAQComponent
                key={faq.id}
                title={faq.title}
                content={faq.content}
              />
            ))}
          </Grid>
        </Grid>
      </Container>
      <Footer bg={false} />
    </>
  );
};

export default AboutUsPage;
