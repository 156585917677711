import { SyntheticEvent, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import SettingEditForm from "../../settingEditForm";

interface EditProfileDialogProps {
  open: boolean;
  handleClose: any;
  userInfo: any;
  ImgPlaceholder: any;
}

const EditProfileDialog = (props: EditProfileDialogProps) => {
  const { open, handleClose, userInfo, ImgPlaceholder } = props;
  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true} scroll="paper">
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Edit Profile
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <Grid container>
          <SettingEditForm
            user_id={userInfo?.user_id}
            img={ImgPlaceholder}
            email={userInfo?.email}
            firstName={userInfo?.first_name}
            lastName={userInfo?.last_name}
            dob={userInfo?.dob}
            contact={userInfo?.contact}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EditProfileDialog;
