import { useFormik } from "formik";
import { FormGroup, FormLabel, Grid, TextField } from "@mui/material";
import moment from "moment";

import ImgPlaceholder from "../../assets/home-2.jpeg";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks/hooks";
import { updateUserSettings } from "../../store/thunks/userThunk";
import { LVLpmThemeButton } from "../../utilities/constants";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

interface userInfoProps {
  user_id: string;
  img: string;
  email: string;
  firstName: string;
  lastName: string;
  dob: any;
  contact: number;
}

const SettingEditForm = (props: userInfoProps) => {
  const [imgValue, setImgValue] = useState("");
  const dispatch = useAppDispatch();
  const [isDisabled, setIsDisabled] = useState(true);
  const { user_id, img, email, firstName, lastName, dob, contact } = props;

  const initialValues = {
    user_id: user_id,
    img: img === "" ? ImgPlaceholder : img,
    email: email,
    firstName: firstName,
    lastName: lastName,
    dob: dob,
    contact: contact,
  };

  const handleProfileImgChange = (e: any) => {
    e.preventDefault();
    const imgValue =
      e.target.files.length === 0 ? "" : URL.createObjectURL(e.target.files[0]);
    if (imgValue !== "") {
      setImgValue(imgValue);
      setIsDisabled(false);
    } else {
      setImgValue("");
      setIsDisabled(true);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: any) => {
      dispatch(updateUserSettings({ values: values })).then(() => {
        location.reload();
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container direction="row" spacing={2} sx={{ marginBottom: "15px" }}>
        <Grid container>
          <Grid
            container
            item
            xs={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FormLabel htmlFor={"img"} sx={{ fontWeight: "bold" }}>
              Profile Image
            </FormLabel>
            <img
              src={imgValue === "" ? img : imgValue}
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                marginBottom: "15px",
              }}
            />
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", alignItems: "end" }}>
            <TextField
              type="file"
              id="img"
              name="img"
              inputProps={{
                accept: "image/png, image/jpeg",
              }}
              onChange={handleProfileImgChange}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="email"
            id="email"
            label="E-mail"
            name="email"
            value={formik.values.email}
            sx={{
              width: "100%",
            }}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="text"
            id="firstName"
            label="First Name"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            sx={{
              width: "100%",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="text"
            id="lastName"
            label="Last Name"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            sx={{
              width: "100%",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              name="dob"
              defaultValue={dayjs(formik.values.dob)}
              onChange={(value: any) => {
                formik.setFieldValue("dob", Date.parse(value));
              }}
              views={["year", "month", "day"]}
              sx={{ width: "100%" }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="number"
            id="contact"
            label="Phone Number"
            name="contact"
            value={formik.values.contact}
            onChange={formik.handleChange}
            sx={{
              width: "100%",
            }}
          />
        </Grid>
      </Grid>
      <LVLpmThemeButton
        variant="contained"
        type="submit"
        disabled={!formik.dirty}
      >
        Update Profile
      </LVLpmThemeButton>
    </form>
  );
};

export default SettingEditForm;
