import { SyntheticEvent, useEffect, useState } from "react";
import { enGB } from "date-fns/locale";
import { DateRangePicker } from "react-date-range";
const CalendarView = (props) => {
  const { existingBookings } = props;
  return (
    <DateRangePicker
      ranges={existingBookings ? existingBookings : []}
      direction="horizontal"
      locale={enGB}
      moveRangeOnFirstSelection={false}
      showDateDisplay={false}
      dragSelectionEnabled={false}
      editableDateInputs={false}
      showMonthAndYearPickers={true}
      disabledDates={existingBookings}
      onChange={() => {}}
      retainEndDateOnFirstSelection={false}
      staticRanges={[]}
      inputRanges={[]}
    />
  );
};

export default CalendarView;
