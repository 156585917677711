import { SyntheticEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Spinner from "../../../../components/reusableComponents/spinner/spinner";
import CloseIcon from "@mui/icons-material/Close";
import Textarea from "@mui/joy/Textarea";
import { LVLpmThemeButton } from "../../../../utilities/constants";
import { updateUpsellProductDetails } from "../../../../store/thunks/upsellThunk";
import { displayConsoleError } from "../../../../utilities/helperFunctions";

interface UpdateUpsellProductDetailsDialogProps {
  open: boolean;
  handleCloseUpdateUpsellProductDetails: (message: string) => void;
  selectedUpsellProductData: any;
}

const UpdateUpsellProductDetailsDialog = (
  props: UpdateUpsellProductDetailsDialogProps
) => {
  const {
    open,
    handleCloseUpdateUpsellProductDetails,
    selectedUpsellProductData,
  } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { updateUpsellProductDetailsLoading } = useAppSelector(
    (state: any) => state.upsell
  );

  const { allAccounts } = useAppSelector((state: any) => state.system);

  const [upsellProductName, setUpsellProductName] = useState("");
  const [upsellProductDescription, setUpsellProductDescription] = useState("");
  const [upsellProductAccount, setUpsellProductAccount] = useState<any>();

  const [updateUpsellProductDetailsError, setUpdateUpsellProductDetailsError] =
    useState(false);
  const [
    updateUpsellProductDetailsErrorMessage,
    setUpdateUpsellProductDetailsErrorMessage,
  ] = useState("");

  const [
    upsellUpdateDetailAccountOptions,
    setUpsellUpdateDetailAccountOptions,
  ] = useState<any[]>([]);

  const [
    enableUpdateUpsellProductDetails,
    setEnableUpdateUpsellProductDetails,
  ] = useState<boolean>(true);

  const checkEnableUpdateUpsellProductDetails = () => {
    if (
      upsellProductName === "" ||
      upsellProductDescription === "" ||
      upsellProductAccount === null
    ) {
      setEnableUpdateUpsellProductDetails(true);
    } else {
      if (
        upsellProductName === selectedUpsellProductData?.upsell_product_name &&
        upsellProductDescription ===
          selectedUpsellProductData?.upsell_product_description &&
        upsellProductAccount?.account_id ===
          selectedUpsellProductData?.account_id
      ) {
        setEnableUpdateUpsellProductDetails(true);
      } else {
        setEnableUpdateUpsellProductDetails(false);
      }
    }
  };

  useEffect(() => {
    if (allAccounts) {
      const accountOptions = allAccounts.filter((account: any) => {
        if (account.upsell) {
          return account;
        }
      });
      setUpsellUpdateDetailAccountOptions(accountOptions);
    }
  }, [allAccounts]);

  const setInitialValues = () => {
    if (selectedUpsellProductData) {
      setUpsellProductName(selectedUpsellProductData?.upsell_product_name);
      setUpsellProductDescription(
        selectedUpsellProductData?.upsell_product_description
      );
      setUpsellProductAccount({
        account_id: selectedUpsellProductData?.account_id,
        account_name: selectedUpsellProductData?.account_name,
        label: selectedUpsellProductData?.account_label,
        upsell: true,
      });
    }
  };

  useEffect(() => {
    if (open && selectedUpsellProductData) {
      if (allAccounts) {
        let accountOptions = allAccounts.filter((account: any) => {
          if (account.upsell) {
            return account;
          }
        });

        setUpsellUpdateDetailAccountOptions(accountOptions);
      }
      setInitialValues();
      checkEnableUpdateUpsellProductDetails();
    }
  }, [selectedUpsellProductData]);

  useEffect(() => {
    if (open) {
      checkEnableUpdateUpsellProductDetails();
    }
  }, [upsellProductName, upsellProductDescription, upsellProductAccount]);

  const submitUpdateUpsellProductDetails = () => {
    setUpdateUpsellProductDetailsError(false);
    setUpdateUpsellProductDetailsErrorMessage("");

    const payload = {
      upsell_product_id: selectedUpsellProductData?.upsell_product_id,
      upsell_product_name: upsellProductName,
      upsell_product_description: upsellProductDescription,
      upsell_account: upsellProductAccount,
    };
    dispatch(
      updateUpsellProductDetails({ upsell_product_details_payload: payload })
    )
      .then((updateUpsellProductDetailsResult: any) => {
        if (updateUpsellProductDetailsResult.payload.result) {
          closeUpdateUpsellProductDetails(
            updateUpsellProductDetailsResult.payload.message
          );
        } else {
          setUpdateUpsellProductDetailsError(true);
          setUpdateUpsellProductDetailsErrorMessage(
            updateUpsellProductDetailsResult.payload.message
          );
        }
      })
      .catch((updateUpsellProductDetailsError: any) => {
        displayConsoleError(
          "updateUpsellProductDetails",
          updateUpsellProductDetailsError
        );
      });
  };

  const closeUpdateUpsellProductDetails = (message: string) => {
    setUpsellProductName("");
    setUpsellProductDescription("");
    setUpsellProductAccount(null);

    handleCloseUpdateUpsellProductDetails(message);
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth={true} scroll="paper">
      <Spinner loading={updateUpsellProductDetailsLoading} />
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Update Product Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={(event: any) => closeUpdateUpsellProductDetails("")}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent dividers>
        <Grid container>
          <Grid container item xs={12}>
            <Grid item xs={4} md={4} lg={4} sx={{ padding: "1%" }}>
              <TextField
                fullWidth
                id="upsell_product_name"
                label="Product Name"
                name="upsell_product_name"
                value={upsellProductName}
                onChange={(e) => setUpsellProductName(e.target.value)}
                error={upsellProductName === "" ? true : false}
                helperText={
                  upsellProductName === "" ? "Product name is required" : ""
                }
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4} sx={{ padding: "1%" }}>
              <Textarea
                minRows={3}
                id="upsell_product_description"
                name="upsell_product_description"
                placeholder="Product Description"
                value={upsellProductDescription}
                onChange={(e) => setUpsellProductDescription(e.target.value)}
                error={upsellProductDescription === "" ? true : false}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4} sx={{ padding: "1%" }}>
              <Autocomplete
                options={upsellUpdateDetailAccountOptions}
                value={upsellProductAccount}
                sx={{ width: "100%" }}
                isOptionEqualToValue={(option: any, value: any) => {
                  return option?.account_id === value?.account_id;
                }}
                getOptionLabel={(option: any) => option?.label}
                onChange={(event: any, value: any) => {
                  setUpsellProductAccount(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                    label={"Select Account Type"}
                    id="upsell_account"
                    name="upsell_account"
                    error={upsellProductAccount === null ? true : false}
                    helperText={
                      upsellProductAccount === null
                        ? "Upsell account is required"
                        : ""
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        {updateUpsellProductDetailsError && (
          <Grid item xs={12}>
            <Typography color="error">
              {updateUpsellProductDetailsErrorMessage}
            </Typography>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <LVLpmThemeButton
          variant="contained"
          onClick={(event: any) => closeUpdateUpsellProductDetails("")}
        >
          Cancel
        </LVLpmThemeButton>
        <LVLpmThemeButton
          variant="contained"
          onClick={submitUpdateUpsellProductDetails}
          disabled={enableUpdateUpsellProductDetails}
        >
          Submit
        </LVLpmThemeButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateUpsellProductDetailsDialog;
