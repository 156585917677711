import { createSlice } from "@reduxjs/toolkit";
import {
  createUserMessageList,
  createUserMessage,
  getUserMessageList,
  getUserMessages,
} from "../../thunks/messagesThunk";

interface MessagesSlice {
  createUserMessageListLoading: boolean;
  createUserMessageLoading: boolean;
  getUserMessageListLoading: boolean;
  getUserMessagesLoading: boolean;
  error: any;
  response: any;
  userMessagesList?: any;
  currentUserMessages?: any;
  currentUserMessageList?: any;
  createdUserMessageList?: any;
  sentMessage: string;
}

const initialState: MessagesSlice = {
  createUserMessageListLoading: false,
  createUserMessageLoading: false,
  getUserMessageListLoading: false,
  getUserMessagesLoading: false,
  error: null,
  response: null,
  userMessagesList: null,
  currentUserMessages: null,
  currentUserMessageList: null,
  sentMessage: "",
};

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.createUserMessageListLoading = false;
      state.createUserMessageLoading = false;
      state.getUserMessageListLoading = false;
      state.getUserMessagesLoading = false;
      state.error = null;
      state.response = null;
      state.userMessagesList = null;
      state.currentUserMessages = null;
      state.currentUserMessageList = null;
      state.sentMessage = "";
    },
    setCurrentUserMessageList: (state, action) => {
      state.currentUserMessageList = action.payload;
    },
    setCreatedUserMessageList: (state, action) => {
      state.createdUserMessageList = action.payload;
    },
    setSentMessage: (state, action) => {
      state.sentMessage = action.payload;
    },
  },
  extraReducers(builder) {
    // Create User Message List
    builder.addCase(createUserMessageList.pending, (state) => {
      state.createUserMessageListLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(createUserMessageList.fulfilled, (state, action) => {
      state.createUserMessageListLoading = false;
      state.response = action.payload;
    });
    builder.addCase(createUserMessageList.rejected, (state, action) => {
      state.createUserMessageListLoading = false;
      state.error = action.payload;
    });
    // Create User Message
    builder.addCase(createUserMessage.pending, (state) => {
      state.createUserMessageListLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(createUserMessage.fulfilled, (state, action) => {
      state.createUserMessageListLoading = false;
      state.response = action.payload;
    });
    builder.addCase(createUserMessage.rejected, (state, action) => {
      state.createUserMessageListLoading = false;
      state.error = action.payload;
    });
    // Get User Message List
    builder.addCase(getUserMessageList.pending, (state) => {
      state.getUserMessageListLoading = true;
      state.error = null;
      state.userMessagesList = null;
    });
    builder.addCase(getUserMessageList.fulfilled, (state, action) => {
      state.getUserMessageListLoading = false;
      state.userMessagesList = action.payload.user_message_list;
    });
    builder.addCase(getUserMessageList.rejected, (state, action) => {
      state.getUserMessageListLoading = false;
      state.error = action.payload;
    });
    // Get User Messages
    builder.addCase(getUserMessages.pending, (state) => {
      state.getUserMessagesLoading = true;
      state.error = null;
      state.currentUserMessages = null;
    });
    builder.addCase(getUserMessages.fulfilled, (state, action) => {
      state.getUserMessagesLoading = false;
      state.currentUserMessages = action.payload.user_messages;
    });
    builder.addCase(getUserMessages.rejected, (state, action) => {
      state.getUserMessagesLoading = false;
      state.error = action.payload;
    });
  },
});

export const {
  setCurrentUserMessageList,
  setCreatedUserMessageList,
  setSentMessage,
  resetMessage,
} = messagesSlice.actions;

export const messagesReducer = messagesSlice.reducer;
