import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  authenticationReducer,
  logout,
  reset,
  setPreviousLocation,
} from "./features/authentication/authenticationSlice";
import {
  setCurrentUserMessageList,
  setCreatedUserMessageList,
  setSentMessage,
  resetMessage,
} from "./features/messages/messagesSlice";
import {
  listingsReducer,
  setSelectedProperty,
  setCurrentDestination,
  setSelectedPropertiesType,
  setSelectedPrices,
  setSearchPropertyName,
} from "./features/listing/listingsSlice";
import { paymentReducer } from "./features/payment/paymentSlice";
import { userReducer } from "./features/user/userSlice";
import { addressRedcuer, resetAddress } from "./features/address/addressSlice";
import {
  bookingsReducer,
  resetBooking,
} from "./features/bookings/bookingsSlice";
import { messagesReducer } from "./features/messages/messagesSlice";
import { systemReducer, resetSystem } from "./features/system/systemSlice";

import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import {
  propertyReducer,
  resetProperty,
} from "./features/property/propertySlice";
import { resetUpsell, upsellReducer } from "./features/upsell/upsellSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  authentication: authenticationReducer,
  listings: listingsReducer,
  payments: paymentReducer,
  user: userReducer,
  address: addressRedcuer,
  property: propertyReducer,
  bookings: bookingsReducer,
  messages: messagesReducer,
  system: systemReducer,
  upsell: upsellReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat();
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export {
  logout,
  reset,
  setPreviousLocation,
  setSelectedProperty,
  setCurrentDestination,
  setSelectedPropertiesType,
  setSelectedPrices,
  setSearchPropertyName,
  setCurrentUserMessageList,
  setCreatedUserMessageList,
  setSentMessage,
  resetMessage,
  resetSystem,
  resetAddress,
  resetBooking,
  resetProperty,
  resetUpsell,
};

export const persistor = persistStore(store);
