import { SyntheticEvent, useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { LVLpmThemeButton } from "../../../utilities/constants";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { getPropertyBookings } from "../../../store/thunks/bookingsThunk";
import { DateRangePicker } from "react-date-range";
import { enGB } from "date-fns/locale";
import { blockPropertyReservation } from "../../../store/thunks/propertyThunk";
import {
  dateRangeOverlap,
  displayConsoleError,
} from "../../../utilities/helperFunctions";
import Spinner from "../../reusableComponents/spinner/spinner";
import { checkLeads } from "../../../store/thunks/paymentThunk";
import CalendarView from "../../reusableComponents/calendarView/calendarView";

interface BlockPorpertyReservationDialogProps {
  open: boolean;
  handleCloseCancel: (event: any) => void;
  selectedPropertyDetails: any;
}

const BlockPorpertyReservationDialog = (
  props: BlockPorpertyReservationDialogProps
) => {
  const { open, handleCloseCancel, selectedPropertyDetails } = props;
  const [existingBookings, setExistingBookings] = useState<any[]>([]);

  const { blockPropertyReservationLoading } = useAppSelector(
    (state: any) => state.property
  );

  const { userInfo } = useAppSelector((state: any) => state.authentication);

  const [blockPorpertyReservationError, setBlockPorpertyReservationError] =
    useState(false);
  const [
    blockPorpertyReservationErrorMessage,
    setBlockPorpertyReservationMessage,
  ] = useState("");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [newCheckin, setNewCheckin] = useState<any>(new Date());
  const [newCheckout, setNewCheckout] = useState<any>(new Date());
  const [totalPrice, setTotalPrice] = useState<any>(0);
  const [lvlpmFee, setLvlpmFee] = useState<any>(0);

  const dispatch = useAppDispatch();

  const handleCloseBlockPorpertyReservationDialog = (event: any) => {
    setNewCheckin(undefined);
    setNewCheckout(undefined);
    handleCloseCancel(event);
  };

  const submitBlockPorpertyReservationDialog = () => {
    const paymentPaylod = {
      user_id: userInfo?.user_id,
      email: userInfo?.email,
      first_name: userInfo?.first_name,
      last_name: userInfo?.last_name,
      property_name: selectedPropertyDetails.property_name,
      property_id: selectedPropertyDetails.property_id,
      hostfully_id: selectedPropertyDetails.hostfully_property_id,
      check_in: dayjs(newCheckin).format("YYYY-MM-DDTHH:mm:ss"),
      check_out: dayjs(newCheckout).format("YYYY-MM-DDTHH:mm:ss"),
      nights:
        newCheckout > newCheckin
          ? Math.ceil((newCheckout - newCheckin) / (1000 * 60 * 60 * 24))
          : 0,
      lvlpm_fee: lvlpmFee,
      totalPrice: totalPrice,
    };
    const leadsInfo: any = {
      hostfully_id: selectedPropertyDetails?.hostfully_property_id,
      lead_range: [
        {
          check_in: newCheckin?.format("YYYY-MM-DD"),
          check_out: newCheckout?.format("YYYY-MM-DD"),
        },
      ],
    };
    dispatch(checkLeads({ leadsInfo }))
      .then((leadResult: any) => {
        if (leadResult.payload?.leads && leadResult.payload?.leads.length > 0) {
          let leadChecking: Boolean = true;
          leadResult.payload?.leads.forEach((currentLeads: any) => {
            if (["NEW", "PENDING", "BOOKED"].includes(currentLeads?.status)) {
              const check = dateRangeOverlap(
                [newCheckin, newCheckout],
                [
                  dayjs(new Date(currentLeads?.checkInZonedDateTime)),
                  dayjs(new Date(currentLeads?.checkOutZonedDateTime)),
                ]
              );
              if (check === false) {
                leadChecking = false;
                setSnackbarMessage(
                  "Unable to verify booking availability dates. Please try again later or contact customer support!"
                );
                setOpenSnackbar(true);
                return;
              }
            }
          });
          if (leadChecking) {
            dispatch(
              getPropertyBookings({
                property_id: selectedPropertyDetails?.property_id,
              })
            )
              .then((result: any) => {
                if (
                  result.payload?.property_bookings &&
                  result.payload?.property_bookings.length > 0
                ) {
                  let checking: Boolean = true;
                  result.payload?.property_bookings.forEach(
                    (property_booking: any) => {
                      const check = dateRangeOverlap(
                        [newCheckin, newCheckout],
                        [
                          dayjs(new Date(property_booking?.check_in)),
                          dayjs(new Date(property_booking?.check_out)),
                        ]
                      );
                      if (check === false) {
                        checking = false;
                        setSnackbarMessage(
                          "Unable to verify booking availability dates. Please try again later or contact customer support!"
                        );
                        setOpenSnackbar(true);
                        return;
                      }
                    }
                  );
                  if (checking) {
                    dispatch(
                      blockPropertyReservation({
                        blockReservationInfo: paymentPaylod,
                      })
                    )
                      .then((updatePropertyPriceResult) => {
                        if (updatePropertyPriceResult?.payload?.status) {
                          handleCloseBlockPorpertyReservationDialog(
                            updatePropertyPriceResult?.payload?.message
                          );
                        } else {
                          setBlockPorpertyReservationError(true);
                          setBlockPorpertyReservationMessage(
                            "Unable to update property Price, check for duplicate property name. Please try again later or contact support"
                          );
                        }
                      })
                      .catch((updatePropertyPriceError) => {
                        displayConsoleError(
                          "updatePropertyPrice",
                          updatePropertyPriceError
                        );
                        setBlockPorpertyReservationError(true);
                        setBlockPorpertyReservationMessage(
                          "Unable to update property Price, check for duplicate property name, Please try again later or contact support"
                        );
                      });
                  }
                } else {
                  dispatch(
                    blockPropertyReservation({
                      blockReservationInfo: paymentPaylod,
                    })
                  )
                    .then((updatePropertyPriceResult) => {
                      if (updatePropertyPriceResult?.payload?.status) {
                        handleCloseBlockPorpertyReservationDialog(
                          updatePropertyPriceResult?.payload?.message
                        );
                      } else {
                        setBlockPorpertyReservationError(true);
                        setBlockPorpertyReservationMessage(
                          "Unable to update property Price, check for duplicate property name. Please try again later or contact support"
                        );
                      }
                    })
                    .catch((updatePropertyPriceError) => {
                      displayConsoleError(
                        "updatePropertyPrice",
                        updatePropertyPriceError
                      );
                      setBlockPorpertyReservationError(true);
                      setBlockPorpertyReservationMessage(
                        "Unable to update property Price, check for duplicate property name, Please try again later or contact support"
                      );
                    });
                }
              })
              .catch((error) => {
                displayConsoleError("getPropertyBookings", error);
                setSnackbarMessage(
                  "Unable to verify booking availability dates. Please try again later or contact customer support!"
                );
                setOpenSnackbar(true);
              });
          }
        } else {
          dispatch(
            getPropertyBookings({
              property_id: selectedPropertyDetails?.property_id,
            })
          )
            .then((result: any) => {
              if (
                result.payload?.property_bookings &&
                result.payload?.property_bookings.length > 0
              ) {
                let checking: Boolean = true;
                result.payload?.property_bookings.forEach(
                  (property_booking: any) => {
                    const check = dateRangeOverlap(
                      [newCheckin, newCheckout],
                      [
                        dayjs(new Date(property_booking?.check_in)),
                        dayjs(new Date(property_booking?.check_out)),
                      ]
                    );
                    if (check === false) {
                      checking = false;
                      setSnackbarMessage(
                        "Unable to verify booking availability dates. Please try again later or contact customer support!"
                      );
                      setOpenSnackbar(true);
                      return;
                    }
                  }
                );
                if (checking) {
                  dispatch(
                    blockPropertyReservation({
                      blockReservationInfo: paymentPaylod,
                    })
                  )
                    .then((updatePropertyPriceResult) => {
                      if (updatePropertyPriceResult?.payload?.status) {
                        handleCloseBlockPorpertyReservationDialog(
                          updatePropertyPriceResult?.payload?.message
                        );
                      } else {
                        setBlockPorpertyReservationError(true);
                        setBlockPorpertyReservationMessage(
                          "Unable to update property Price, check for duplicate property name. Please try again later or contact support"
                        );
                      }
                    })
                    .catch((updatePropertyPriceError) => {
                      displayConsoleError(
                        "updatePropertyPrice",
                        updatePropertyPriceError
                      );
                      setBlockPorpertyReservationError(true);
                      setBlockPorpertyReservationMessage(
                        "Unable to update property Price, check for duplicate property name, Please try again later or contact support"
                      );
                    });
                }
              } else {
                dispatch(
                  blockPropertyReservation({
                    blockReservationInfo: paymentPaylod,
                  })
                )
                  .then((updatePropertyPriceResult) => {
                    if (updatePropertyPriceResult?.payload?.status) {
                      handleCloseBlockPorpertyReservationDialog(
                        updatePropertyPriceResult?.payload?.message
                      );
                    } else {
                      setBlockPorpertyReservationError(true);
                      setBlockPorpertyReservationMessage(
                        "Unable to update property Price, check for duplicate property name. Please try again later or contact support"
                      );
                    }
                  })
                  .catch((updatePropertyPriceError) => {
                    displayConsoleError(
                      "updatePropertyPrice",
                      updatePropertyPriceError
                    );
                    setBlockPorpertyReservationError(true);
                    setBlockPorpertyReservationMessage(
                      "Unable to update property Price, check for duplicate property name, Please try again later or contact support"
                    );
                  });
              }
            })
            .catch((error) => {
              displayConsoleError("getPropertyBookings", error);
              setSnackbarMessage(
                "Unable to verify booking availability dates. Please try again later or contact customer support!"
              );
              setOpenSnackbar(true);
            });
        }
      })
      .catch((leadsInfoCheckingError: any) => {
        displayConsoleError("leadsInfoCheckingError", leadsInfoCheckingError);
        setSnackbarMessage(
          "Unable to verify booking availability dates. Please try again later or contact customer support!"
        );
        setOpenSnackbar(true);
      });
  };

  useEffect(() => {
    setNewCheckin(new Date());
    setNewCheckout(new Date());
    if (selectedPropertyDetails) {
      dispatch(
        getPropertyBookings({
          property_id: selectedPropertyDetails?.property_id,
        })
      ).then((result: any) => {
        if (
          result.payload?.property_bookings &&
          result.payload?.property_bookings.length > 0
        ) {
          const temp_bookings: any[] = [];
          result.payload?.property_bookings.forEach((property_booking: any) => {
            const temp_booking = {
              startDate: new Date(property_booking?.check_in),
              endDate: new Date(property_booking?.check_out),
              key: "selection",
              color: "blue",
            };
            temp_bookings.push(temp_booking);
          });
          setExistingBookings(temp_bookings);
        }
      });
    }
  }, [selectedPropertyDetails]);

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true}>
      <Spinner loading={blockPropertyReservationLoading} />
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Block Property Reservation
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleCloseBlockPorpertyReservationDialog("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <Grid container>
          <Grid item xs={6} sx={{ padding: "1%" }}>
            <TextField
              id="property_name"
              label="Property Name"
              name="property_name"
              value={selectedPropertyDetails?.property_name}
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "1%", gap: 2 }}>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12} sx={{}}>
              <Typography variant="h5">Reservation</Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", marginBottom: "5px" }}
                >
                  Check In
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="checkIn"
                    value={dayjs(new Date(newCheckin)).utc()}
                    onChange={(value: any) => {
                      setNewCheckin(value);
                    }}
                    disablePast
                    views={["year", "month", "day"]}
                    sx={{ width: "100%" }}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", marginBottom: "5px" }}
              >
                Check Out
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="checkOut"
                  value={dayjs(new Date(newCheckout)).utc()}
                  onChange={(value: any) => {
                    setNewCheckout(value);
                    // checkUpdateRequest(newCheckin, value, newGuests);
                  }}
                  disablePast
                  views={["year", "month", "day"]}
                  sx={{ width: "100%" }}
                  minDate={dayjs(newCheckin)}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          {existingBookings.length > 0 && (
            <Grid
              container
              item
              md={6}
              xs={12}
              sx={{ display: "flex", justifyContent: "center", padding: "2%" }}
            >
              <Grid item xs={12}>
                <Typography variant="h4">Property Availbility</Typography>
              </Grid>
              <Grid item xs={12}>
                <CalendarView existingBookings={existingBookings} />
              </Grid>
            </Grid>
          )}
        </Grid>
        {blockPorpertyReservationError && (
          <Grid
            container
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography sx={{ color: "red" }}>
              {blockPorpertyReservationErrorMessage}
            </Typography>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => handleCloseBlockPorpertyReservationDialog("")}
        >
          Cancel
        </LVLpmThemeButton>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => submitBlockPorpertyReservationDialog()}
        >
          Submit
        </LVLpmThemeButton>
      </DialogActions>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        open={openSnackbar}
        message={snackbarMessage}
        key={"topcenter"}
        onClose={() => setOpenSnackbar(false)}
      />
    </Dialog>
  );
};

export default BlockPorpertyReservationDialog;
