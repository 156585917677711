import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { tokenInterceptor } from "../../utilities/authMidleware";
import { backendURL, config } from "../../utilities/utils";
import { displayConsoleError } from "../../utilities/helperFunctions";

const upsellRoute = "/api/v1/upsell";

const getAllUpsellProducts = createAsyncThunk(
  "upsell/getAllUpsellProducts",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}${upsellRoute}/getAllUpsellProducts`,
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getAllUpsellProducts", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const addUpsellProduct = createAsyncThunk(
  "upsell/addUpsellProduct",
  async (
    { upsellProductPayload }: { upsellProductPayload: any },
    { rejectWithValue }
  ) => {
    const tempHeaders = tokenInterceptor();

    const formData = new FormData();
    formData.append(
      "upsell_product_name",
      upsellProductPayload.upsell_product_name
    );
    formData.append(
      "upsell_product_description",
      upsellProductPayload.upsell_product_description
    );
    formData.append(
      "upsell_product_price",
      upsellProductPayload.upsell_product_price
    );
    formData.append(
      "upsell_product_type",
      upsellProductPayload.upsell_product_type
    );
    formData.append("upsell_account", upsellProductPayload.upsell_account);
    formData.append(
      "upsell_product_interval",
      upsellProductPayload.upsell_product_interval
    );
    formData.append(
      "upsell_product_interval_frequency",
      upsellProductPayload.upsell_product_interval_frequency
    );

    formData.append("account_id", upsellProductPayload.account_id);
    formData.append("account_name", upsellProductPayload.account_name);
    formData.append("accountlabel", upsellProductPayload.account_label);

    formData.append(
      "upsell_product_type_id",
      upsellProductPayload.upsell_product_type_id
    );
    formData.append(
      "upsell_product_type_name",
      upsellProductPayload.upsell_product_type_name
    );
    formData.append(
      "upsell_product_type_label",
      upsellProductPayload.upsell_product_type_label
    );

    formData.append(
      "upsell_product_interval_id",
      upsellProductPayload.upsell_product_interval_id
    );
    formData.append(
      "upsell_product_interval_name",
      upsellProductPayload.upsell_product_interval_name
    );
    formData.append(
      "upsell_product_interval_label",
      upsellProductPayload.upsell_product_interval_label
    );

    const upsellProductImages = Array.from(
      upsellProductPayload?.upsell_product_image
    );

    upsellProductImages.forEach((element: any, index: any) => {
      formData.append(`upsell_product_image`, element);
    });

    try {
      const response = await axios.post(
        `${backendURL}${upsellRoute}/addUpsellProduct`,
        formData,
        {
          ...tempHeaders,
          headers: {
            ...tempHeaders.headers,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("addUpsellProduct", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const enableUpsellProduct = createAsyncThunk(
  "upsell/enableUpsellProduct",
  async (
    { upsell_product_id }: { upsell_product_id: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}${upsellRoute}/enableUpsellProduct`,
        { upsell_product_id },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("enableUpsellProduct", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const disableUpsellProduct = createAsyncThunk(
  "upsell/disableUpsellProduct",
  async (
    { upsell_product_id }: { upsell_product_id: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}${upsellRoute}/disableUpsellProduct`,
        { upsell_product_id },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("disableUpsellProduct", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const updateUpsellProductDetails = createAsyncThunk(
  "upsell/updateUpsellProductDetails",
  async (
    { upsell_product_details_payload }: { upsell_product_details_payload: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}${upsellRoute}/updateUpsellProductDetails`,
        { upsell_product_details_payload },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("updateUpsellProductDetails", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getUpsellProductTypes = createAsyncThunk(
  "upsell/getUpsellProductTypes",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}${upsellRoute}/getUpsellProductTypes`,
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getUpsellProductTypes", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getUpsellProductIntervals = createAsyncThunk(
  "upsell/getUpsellProductIntervals",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}${upsellRoute}/getUpsellProductIntervals`,
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getUpsellProductIntervals", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const updateUpsellProductImage = createAsyncThunk(
  "upsell/updateUpsellProductImage",
  async (
    { upsell_product_image_payload }: { upsell_product_image_payload: any },
    { rejectWithValue }
  ) => {
    const tempHeaders = tokenInterceptor();

    const formData = new FormData();
    formData.append(
      "upsell_product_id",
      upsell_product_image_payload.upsell_product_id
    );
    formData.append(
      "upsell_product_image_path",
      upsell_product_image_payload.upsell_product_image_path
    );

    const upsellProductImages = Array.from(
      upsell_product_image_payload?.images
    );

    upsellProductImages.forEach((element: any, index: any) => {
      formData.append(`upsell_product_image`, element);
    });

    try {
      const response = await axios.post(
        `${backendURL}${upsellRoute}/updateUpsellProductImage`,
        formData,
        {
          ...tempHeaders,
          headers: {
            ...tempHeaders.headers,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("updateUpsellProductImage", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export {
  getAllUpsellProducts,
  addUpsellProduct,
  enableUpsellProduct,
  disableUpsellProduct,
  updateUpsellProductDetails,
  getUpsellProductTypes,
  getUpsellProductIntervals,
  updateUpsellProductImage,
};
