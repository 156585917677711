import { createSlice } from "@reduxjs/toolkit";
import {
  processPayment,
  checkLeads,
  paymentIntent,
  cancelPaymentIntent,
} from "../../thunks/paymentThunk";

interface PaymentState {
  paymentLoading: Boolean;
  paymentInfo: object | null;
  error: any;
  response: any;
  success: Boolean;
}
const initialState: PaymentState = {
  paymentLoading: false,
  paymentInfo: {},
  error: null,
  response: null,
  success: false,
};

export const paymentSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    resetPayment: (state) => {
      state.paymentLoading = false;
      state.paymentInfo = {};
      state.error = null;
      state.response = null;
      state.success = false;
    },
  },
  extraReducers(builder) {
    // Process Payment
    builder.addCase(processPayment.pending, (state) => {
      state.paymentLoading = true;
      state.error = null;
    });
    builder.addCase(processPayment.fulfilled, (state, action) => {
      state.response = action.payload;
      state.paymentLoading = false;
      state.success = true;
    });
    builder.addCase(processPayment.rejected, (state, action) => {
      state.paymentLoading = false;
      state.error = action.payload;
      state.success = false;
    });
    // Check Leads
    builder.addCase(checkLeads.pending, (state) => {
      state.paymentLoading = true;
      state.error = null;
    });
    builder.addCase(checkLeads.fulfilled, (state, action) => {
      state.response = action.payload;
      state.paymentLoading = false;
    });
    builder.addCase(checkLeads.rejected, (state, action) => {
      state.paymentLoading = false;
      state.error = action.payload;
    });
    // Payment Intent
    builder.addCase(paymentIntent.pending, (state) => {
      state.paymentLoading = true;
      state.error = null;
    });
    builder.addCase(paymentIntent.fulfilled, (state, action) => {
      state.response = action.payload;
      state.paymentLoading = false;
    });
    builder.addCase(paymentIntent.rejected, (state, action) => {
      state.paymentLoading = false;
      state.error = action.payload;
    });
    // Cancel Payment Intent
    builder.addCase(cancelPaymentIntent.pending, (state) => {
      state.paymentLoading = true;
      state.error = null;
    });
    builder.addCase(cancelPaymentIntent.fulfilled, (state, action) => {
      state.response = action.payload;
      state.paymentLoading = false;
    });
    builder.addCase(cancelPaymentIntent.rejected, (state, action) => {
      state.paymentLoading = false;
      state.error = action.payload;
    });
  },
});
export const { resetPayment } = paymentSlice.actions;
export const paymentReducer = paymentSlice.reducer;
