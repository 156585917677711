import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";

const localizer = dayjsLocalizer(dayjs);

const CalendarComponent = (props) => {
  const { ownerCalendarBookings } = props;

  return (
    <div>
      <Calendar
        events={ownerCalendarBookings}
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
      />
    </div>
  );
};

export default CalendarComponent;
