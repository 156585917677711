import {
  Box,
  Grid,
  ListItemIcon,
  MenuItem,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import TableTemplate from "../../../components/reusableComponents/tableTemplate/tableTemplate";
import {
  getAllBookings,
  getAllPlatforms,
} from "../../../store/thunks/systemThunk";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import LockResetIcon from "@mui/icons-material/LockReset";
import { displayConsoleError } from "../../../utilities/helperFunctions";
import UpdateBookingDialog from "../../../components/dialogComponents/updateBookingDialog/updatebookingDialog";
import CancelBookingDialog from "../../../components/dialogComponents/cancelBookingDialog/cancelBookingDialog";
import UpdateBookingFeeDialog from "./updateBookingFeeDialog/updateBookingFeeDialog";

const BookingManagement = () => {
  const { userInfo } = useAppSelector((state: any) => state.authentication);
  const { getAllBookingsLoading } = useAppSelector(
    (state: any) => state.system
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [allBookings, setAllBookings] = useState([]);

  const [openUpdateBooking, setOpenUpdateBooking] = useState(false);
  const [openCancelBooking, setOpenCancelBooking] = useState(false);
  const [openUpdateBookingFee, setOpenUpdateBookingFee] = useState(false);

  const [selectedBookingPropertyDetails, setSelectedBookingPropertyDetails] =
    useState<any>();

  const {
    data: { data = [] } = {}, //your data and api response will probably be different
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<any>({
    queryKey: ["table-data"],
    queryFn: async () => {
      const response: any = await dispatch(getAllBookings({}))
        .then((getAllBookingsResult: any) => {
          if (getAllBookingsResult?.payload) {
            setAllBookings(getAllBookingsResult?.payload?.bookings_details);
          }
        })
        .catch((getAllBookingsError) => {
          displayConsoleError(
            "bookingmanagement getAllBookings",
            getAllBookingsError
          );
          if (
            getAllBookingsError.response &&
            getAllBookingsError.response.status === 401
          ) {
            navigate("/login");
          }
        });
      return response ? response : {};
    },
    placeholderData: keepPreviousData,
  });

  const bookingColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "property_name",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Property Name",
      },
      {
        accessorKey: "access_code",
        header: "Access Code",
      },

      {
        accessorFn: (row: any) => dayjs(row.check_in).format("MMM DD, YYYY"),
        id: "check_in",
        header: "Check In",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorFn: (row: any) => dayjs(row.check_out).format("MMM DD, YYYY"),
        id: "check_out",
        header: "Check Out",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorFn: (row: any) => `${row.first_name} ${row.last_name}`,
        id: "name",
        header: "Renter Full Name",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },

      {
        accessorKey: "total_price",
        header: "Price",
      },
      {
        accessorKey: "nights",
        header: "Nights",
      },
      {
        accessorKey: "number_of_guests",
        header: "Guests",
      },
      {
        accessorFn: (row: any) =>
          dayjs(row.booking_create_date).format("MMM DD, YYYY"),
        id: "booking_create_date",
        header: "Booking Date",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },

      {
        accessorKey: "status_label",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Status",
      },
    ],
    []
  );

  const bookingTable = useMaterialReactTable({
    columns: bookingColumns,
    data: allBookings ? allBookings : [],
    state: {
      isLoading: getAllBookingsLoading,
    },
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: false,
    enableFacetedValues: true,
    enableRowActions: true,
    enableRowSelection: false,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [5, 10, 20],
      shape: "rounded",
      variant: "outlined",
    },

    renderRowActionMenuItems: ({ closeMenu, row }) => [
      <MenuItem
        key={1}
        onClick={() => {
          setSelectedBookingPropertyDetails(row.original);
          setOpenUpdateBooking(true);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        Update Booking
      </MenuItem>,
      <MenuItem
        key={2}
        onClick={() => {
          setSelectedBookingPropertyDetails(row.original);
          setOpenCancelBooking(true);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        Cancel Booking
      </MenuItem>,
      <MenuItem
        key={3}
        onClick={() => {
          setSelectedBookingPropertyDetails(row.original);
          setOpenUpdateBookingFee(true);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        Update Booking Fee
      </MenuItem>,
    ],
  });

  const handleCloseUpdateBooking = (event: any) => {
    if (event !== "") {
      setOpenSnackbar(true);
      setSnackbarMessage(event);
      refetch();
    }
    setSelectedBookingPropertyDetails(null);
    setOpenUpdateBooking(false);
  };

  const handleCloseCancelBooking = (event: any) => {
    if (event !== "") {
      setOpenSnackbar(true);
      setSnackbarMessage(event);
      refetch();
    }
    setSelectedBookingPropertyDetails(null);
    setOpenCancelBooking(false);
  };

  const handleClosesetOpenUpdateBookingFee = (event: any) => {
    if (event !== "") {
      setOpenSnackbar(true);
      setSnackbarMessage(event);
      refetch();
    }
    setSelectedBookingPropertyDetails(null);
    setOpenUpdateBookingFee(false);
  };

  useEffect(() => {
    dispatch(getAllPlatforms({}));
  }, []);

  return (
    <Grid container>
      <Grid container item xs={12} sx={{ padding: "20px 10px 20px 10px" }}>
        <TableTemplate tableData={bookingTable} />
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        open={openSnackbar}
        message={snackbarMessage}
        key={"topcenter"}
        onClose={() => setOpenSnackbar(false)}
      />
      <UpdateBookingDialog
        open={openUpdateBooking}
        handleCloseUpdate={(event: any) => handleCloseUpdateBooking(event)}
        propertyDetails={selectedBookingPropertyDetails}
        bookingDetails={selectedBookingPropertyDetails}
      />
      <CancelBookingDialog
        open={openCancelBooking}
        handleCloseCancel={(event: any) => handleCloseCancelBooking(event)}
        propertyDetails={selectedBookingPropertyDetails}
        bookingDetails={selectedBookingPropertyDetails}
      />
      <UpdateBookingFeeDialog
        open={openUpdateBookingFee}
        handleCloseCancel={(event: any) =>
          handleClosesetOpenUpdateBookingFee(event)
        }
        selectedBookingDetails={selectedBookingPropertyDetails}
      />
    </Grid>
  );
};
export default BookingManagement;
