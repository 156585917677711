import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

interface RentalCardProps {
  title: string;
  subTitle: string;
  img: string;
  buttonTitle: string;
}

const RentalCard = (props: RentalCardProps) => {
  const { title, subTitle, img, buttonTitle } = props;
  return (
    <Card
      sx={{ width: "100%", backgroundColor: "#EBFDFF", textAlign: "center" }}
    >
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{ fontWeight: "bold" }}
        >
          {title}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {subTitle}
        </Typography>
      </CardContent>
      <CardMedia
        sx={{ height: 150, width: 150, borderRadius: "50%", margin: "auto" }}
        image={img}
        title="green iguana"
      />

      <CardActions>
        <Button
          size="small"
          sx={{
            border: "1px solid #00BDD6",
            padding: "10px",
            width: "100%",
            backgroundColor: "white",
            color: "#00BDD6",
          }}
        >
          {buttonTitle}
        </Button>
      </CardActions>
    </Card>
  );
};
export default RentalCard;
