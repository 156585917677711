import React from "react";
import Carousel from "react-multi-carousel";
import RecommendCard from "../../components/reusableComponents/recommendCard/recommendCard";
import Linen from "../../assets/Image 334.png";
import Solar from "../../assets/Image 334 (1).png";
import Trend from "../../assets/Image 334 (2).png";
import Explore from "../../assets/Image 334 (3).png";
import { Grid } from "@mui/material";
import RentalCard from "./RentalCard";
const dummyData = [
  {
    id: 1,
    title: "Linen Program",
    subtitle: "Fresh. Hyopallergenic. Bamboo",
    image: Linen,
    btnTitle: "Upgrade plan",
  },
  {
    id: 2,
    title: "Solar Program",
    subtitle: "Off-Grid. Increase Equity.",
    image: Solar,
    btnTitle: "Submit Electric Report",
  },
  {
    id: 3,
    title: "Trendy Stay",
    subtitle: "Influcencers. UGC. Viral Marketing",
    image: Trend,
    btnTitle: "Upgrade plan",
  },
  {
    id: 4,
    title: "ExploreStay",
    subtitle: "Content. Travel Bloggers. Promotion",
    image: Explore,
    btnTitle: "Upgrade plan",
  },
  {
    id: 5,
    title: "Linen Program",
    subtitle: "Fresh. Hyopallergenic. Bamboo",
    image: Linen,
    btnTitle: "Upgrade plan",
  },
];

const RentalCarousel = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <Carousel
      containerClass="carousel-container"
      itemClass="carousel-item"
      responsive={responsive}
      autoPlay={true}
      autoPlaySpeed={5000}
      swipeable={true}
      infinite={true}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      className="dashboardFooter_carousel"
    >
      {dummyData.map((data: any) => (
        <Grid container spacing={2} sx={{ paddingRight: "10px" }}>
          <Grid item xs={12}>
            <RentalCard
              key={data.id}
              title={data.title}
              subTitle={data.subtitle}
              img={data.image}
              buttonTitle={data.btnTitle}
            />
          </Grid>
        </Grid>
      ))}
    </Carousel>
  );
};

export default RentalCarousel;
