import { SyntheticEvent, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
} from "@mui/material";
import HorizontalLinearStepper from "./HorizontalLinearStepper";

interface AddPropertyDialogProps {
  open: boolean;
  handleClose: any;
  isLoaded: boolean;
}

const AddPropertyDialog = (props: AddPropertyDialogProps) => {
  const { open, handleClose, isLoaded } = props;
  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true} scroll="paper">
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Create New Listing!
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        {isLoaded && <HorizontalLinearStepper handleOnClose={handleClose} />}
      </DialogContent>
    </Dialog>
  );
};

export default AddPropertyDialog;
